import React, { useEffect } from 'react';
import styled, { StyleSheetManager, ThemedStyledProps, css } from 'styled-components';
import { createPortal } from 'react-dom';
import { initial } from 'lodash';
import { createShadow } from '../helper';

const BaseDrawerOverlay = styled.div<{ open?: boolean }>`
  position: fixed;
  top: 0px;
  right: 0px;
  background-color: rgba(0, 0, 0, 0.5);
  width: 100%;
  display: ${props => (props.open ? 'block' : 'none')};
  height: 100%;
  z-index: 9997;
`;

const BaseDrawer = styled.div<{ open?: boolean; fullScreen: boolean }>`
  position: fixed;
  top: 0px;
  right: ${props => (props.open ? '0px' : props.fullScreen ? '-100%' : '-450px')}};
  display: block;
  background-color: #fff;
  width: ${props => (props.fullScreen ? '100%' : '450px')};
  height: 100%;
  z-index: 9998;
  box-sizing: border-box;
  text-align: left;
  transition: right 0.7s ease-in-out;
  overflow: auto;
  &::-webkit-scrollbar {
    width: 0px;
    background: transparent;
  }
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
  padding-bottom: 100px;
`;

const BaseDrawerBottom = styled.div<{ open?: boolean; fullScreen: boolean }>`
position: fixed;
  bottom: 0px;
  right: ${props => (props.open ? '0px' : props.fullScreen ? '-100%' : '-450px')}};
  display: block;
  background-color: #fff;
  border-top: 1px solid #AEB2B4;
  width: ${props => (props.fullScreen ? '100%' : '450px')};
  z-index: 9999;
  box-sizing: border-box;
  text-align: left;
  transition: right 0.7s ease-in-out;
  padding: 20px;
`;

interface DrawerProps {
  open: boolean;
  onOverlayClick?: () => void;
  children?: any;
  fullscreen?: boolean;
  bottomContent?: any;
}

const Drawer = ({ open, children, bottomContent, onOverlayClick, fullscreen }: DrawerProps) => {
  const [shadow, setShadow] = React.useState<ShadowRoot | null>(null);

  useEffect(() => {
    const shadow = createShadow(document.body);
    setShadow(shadow);
  }, []);

  return (
    <>
      {shadow &&
        createPortal(
          <StyleSheetManager target={shadow}>
            <div style={{ all: 'initial' }}>
              <BaseDrawerOverlay open={open} onClick={() => onOverlayClick && onOverlayClick()} className="SeminargoDrawerOverlay" />
              <BaseDrawer fullScreen={fullscreen || false} open={open} className="SeminargoDrawer">
                {children}
              </BaseDrawer>
              <BaseDrawerBottom fullScreen={fullscreen || false} open={open} className="SeminargoDrawerBottom">
                {bottomContent}
              </BaseDrawerBottom>
            </div>
          </StyleSheetManager>,
          shadow,
        )}
    </>
  );
};

export default Drawer;
