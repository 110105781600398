export function ValidateEmail(email: string) {
  var validRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;

  if (email.match(validRegex)) {
    return true;
  } else {
    return false;
  }
}

export function createShadow(root: any) {
  const div = document.createElement('div');
  const style = document.createElement('style');
  style.innerHTML = ':host { all: initial; }';
  const shadow = div.attachShadow({ mode: 'open' });
  shadow.appendChild(style);
  root.appendChild(div);
  return shadow;
}

export function shadowRootFocusListener(e: Event) {
  const el = e.target as HTMLElement;
  if (el && el.getAttribute) {
    (document.querySelector('#seminargo-sr-div') as HTMLDivElement)?.click();
    document.querySelector('#seminargo-widget')?.removeAttribute('aria-label');
    if (el.getAttribute('aria-label')) {
      document.querySelector('#seminargo-widget')?.setAttribute('aria-label', el.getAttribute('aria-label') || '');
    }
    (document.querySelector('#seminargo-widget') as HTMLDivElement).click();
  }
}
