import React, { useEffect, useLayoutEffect, useRef, useState } from 'react';
import { orderBy } from 'lodash';
import { TCountryCode } from 'countries-list';
import CapsuleTab from './CapsuleTab';
import Button from '../Button';
import 'react-calendar/dist/Calendar.css';
import { format, addDays, endOfDay } from 'date-fns/fp';
import Icon from '../Icon';
import Drawer from '../Drawer';
import { differenceInDays } from 'date-fns';
import { getDownloadUrl, getServiceTypes, placeReservation, verifyReservationEmail } from '../../api/api';
import Spinner from '../Spinner';
import DrawerAccordion from '../DrawerAccordion';
import { ValidateEmail } from '../../helper';

import { QuickPriceCalculator, QuickPriceCalculatorType } from '../../semshared/pricelist/quickprice';
import { BaseCapsule, CapsuleAd, CapsuleTabContainer, ClickOverlay } from './CapsuleComponents';
import BookingForm from './sections/BookingForm';
import CalendarSection from './sections/CalendarSection';
import ServiceSection from './sections/ServiceSection';
import GuestSection from './sections/GuestsSection';
import MeetingRoomsSectionPopup from './sections/MeetingRoomsSectionPopup';
import MeetingRoomsSectionDrawer from './sections/MeetingRoomsSectionDrawer';
import RoomsSection from './sections/RoomsSection';
import ExtrasSection, { hasFormStateAnyVisibleExtras } from './sections/ExtrasSection';
import { position } from 'polished';
import { Availability, AvailabilityType } from '../../semshared/availability/availability';
import { context, trace } from '@opentelemetry/api';
import { OtelManager } from '../../api/otel';

interface CapsuleProps {
  semPlan: string | null;
  label: string;
  styles?: string;
  color: string;
  hotelRef?: string;
  noRooms: boolean;
  slimMode: boolean;
  expandedMode: boolean;
  privacyPolicyUrl: string | null;
  termsUrl: string | null;
  shadowRoot: ShadowRoot | null;
  hotelName: string | null;
}

export interface AddonProduct {
  visible: boolean;
  checked: boolean;
  sku: string;
  name: string;
  header: string;
  details: string;
  isAddon: boolean;
  isDeduction: boolean;
  lookupCode: string;
  recurring: string;
  count: number;
}

export interface AddonFacility {
  visible: boolean;
  checked: boolean;
  sku: string;
  name: string;
  header: string;
  details: string;
  lookupCode: string;
  recurring: string;
  count: number;
}

export interface Day {
  day: number;
  overnightGuests: number;
  totalGuests: number;
  occupancy: string[];
  seating: string[];
  addonProducts: AddonProduct[];
  addonFacilities: AddonFacility[];
}

export interface IFormState {
  start: Date;
  end: Date;
  duration: string;
  meetingRooms: number;
  prevdayGuests: number;
  rooms: number;
  price: string;
  days: Day[];
  service: any;
  phone: string;
  company: string;
  firstname: string;
  lastname: string;
  address: string;
  zip: string;
  city: string;
  country: TCountryCode;
  companyEqualsBilling: boolean;
  billingCompany: string;
  billingFirstname: string;
  billingLastname: string;
  billingAddress: string;
  billingZip: string;
  billingCity: string;
  billingCountry: TCountryCode;
  email: string;
  comment: string;
}

type ValuePiece = Date | null;

const initialFormState: IFormState = {
  start: addDays(1, new Date()),
  end: addDays(2, new Date()),
  duration: 'Ganztag',
  meetingRooms: 1,
  days: [
    {
      day: 0,
      overnightGuests: 0,
      totalGuests: 0,
      occupancy: ['FULLDAY'],
      seating: ['UFORM'],
      addonProducts: [],
      addonFacilities: [],
    },
    {
      day: 1,
      overnightGuests: 0,
      totalGuests: 0,
      occupancy: ['FULLDAY'],
      seating: ['UFORM'],
      addonProducts: [],
      addonFacilities: [],
    },
  ],
  rooms: 20,
  prevdayGuests: 0,
  service: {},
  price: '0',
  phone: '',
  company: '',
  firstname: '',
  lastname: '',
  address: '',
  zip: '',
  city: '',
  country: 'AT',
  billingCompany: '',
  billingFirstname: '',
  billingLastname: '',
  billingAddress: '',
  billingZip: '',
  billingCity: '',
  billingCountry: 'AT',
  companyEqualsBilling: true,
  email: '',
  comment: '',
};

const Capsule = ({
  semPlan,
  label,
  styles,
  color,
  hotelRef,
  noRooms,
  slimMode,
  expandedMode,
  privacyPolicyUrl,
  termsUrl,
  shadowRoot,
  hotelName,
}: CapsuleProps) => {
  const isMobileSize = () => {
    return document.documentElement.clientWidth < 920;
  };

  const isSlimMode = () => {
    return isMobileSize() || slimMode;
  };

  const [expanded, setExpanded] = React.useState(isMobileSize() ? false : expandedMode);

  const [totalPriceNet, setTotalPriceNet] = React.useState<string | null>(null);
  const [totalPriceGross, setTotalPriceGross] = React.useState<string | null>(null);

  const [popupShow, setPopupShow] = React.useState<string | null>(null);
  const [drawerOpen, setDrawerOpen] = React.useState(false);
  const [termsAccepted, setTermsAccepted] = React.useState(false);
  const [reservationStatus, setReservationStatus] = React.useState<string>('READY');
  const [emailVerificationCode, setEmailVerificationCode] = React.useState('');
  const [emailVerificationCodeInvalid, setEmailVerificationCodeInvalid] = React.useState(false);

  const [formState, setFormState] = React.useState<IFormState>(initialFormState);
  const prevFormStateRef = useRef<IFormState>(initialFormState);

  const [popupExpanded, setPopupExpanded] = React.useState(false);
  const [serviceTypes, setServiceTypes] = React.useState<any[]>([]);
  const [serviceTypesError, setServiceTypesError] = React.useState<string>('');
  const [quickPriceCalculator, setQuickPriceCalculator] = React.useState<QuickPriceCalculatorType | null>(null);
  const [availabilityChecker, setAvailabilityChecker] = React.useState<AvailabilityType | null>(null);
  const [meetingRoomsDay, setMeetingRoomsDay] = React.useState(0);
  const [errors, setErrors] = React.useState<any>({});

  const [calValue, onChange] = useState<ValuePiece | [ValuePiece, ValuePiece]>([formState.start, formState.end]);

  const _initDailyAddons = (serviceTypes: any, service: any) => {
    const aaf: AddonFacility[] = [];
    const aap: AddonProduct[] = [];

    for (const serviceType of serviceTypes) {
      for (const addonFacility of serviceType.addonFacilities || []) {
        if (!aaf.find(f => f.sku === addonFacility.sku)) {
          aaf.push({
            ...addonFacility,
            visible: false,
            checked: false,
            count: 1,
          });
        }
      }
      for (const addonProduct of serviceType.addonProducts || []) {
        if (!aap.find(p => p.sku === addonProduct.sku)) {
          aap.push({
            ...addonProduct,
            visible: false,
            checked: false,
            count: 1,
          });
        }
      }
    }
    const result = {
      addonFacilities: _serviceTypeApplyAddonFacilitiesVisibility(service, orderBy(aaf, 'header', 'asc')),
      addonProducts: _serviceTypeApplyAddonProductsVisibility(service, orderBy(aap, 'header', 'asc')),
    };
    return result;
  };
  const _serviceTypeApplyAddonFacilitiesVisibility = (service: any, addons: AddonFacility[]) => {
    addons.forEach(a => {
      a.visible = service.addonFacilities?.findIndex((af: any) => af.sku === a.sku) >= 0;
    });
    return addons;
  };
  const _serviceTypeApplyAddonProductsVisibility = (service: any, addons: AddonProduct[]) => {
    addons.forEach(a => {
      a.visible = service.addonProducts?.findIndex((ap: any) => ap.sku === a.sku) >= 0;
    });
    return addons;
  };

  const onCalChanged = (value: ValuePiece | [ValuePiece, ValuePiece]) => {
    if (Array.isArray(value) && value.length === 2) {
      const days = differenceInDays(value[1] || formState.end, value[0] || formState.start);
      let daysArray = [];
      if (days >= formState.days.length) {
        daysArray = [
          ...formState.days,
          ...Array.from({ length: days - formState.days.length + 1 }, (_, i) => ({
            day: i + formState.days.length,
            ..._initDailyAddons(serviceTypes, formState.service),
            overnightGuests: 0,
            totalGuests: 0,
            occupancy: Array.from({ length: formState.meetingRooms }, (_, i) => 'FULLDAY'),
            seating: Array.from({ length: formState.meetingRooms }, (_, i) => 'UFORM'),
          })),
        ];
      } else {
        daysArray = formState.days.slice(0, days + 1);
      }
      setFormState({
        ...formState,
        start: (Array.isArray(value) && value[0]) || endOfDay(formState.start),
        end: (Array.isArray(value) && value[1]) || endOfDay(formState.start),
        days: daysArray,
      });
      onChange(value);
    }
  };

  useEffect(() => {
    if (hotelRef) {
      getServiceTypes({ hotelRef, language: 'de' })
        .then((res: any) => {
          if (res && res.serviceTypes && Array.isArray(res.serviceTypes) && res.serviceTypes.length > 0) {
            setServiceTypes(res.serviceTypes);
            const service = res.serviceTypes.length > 1 ? res.serviceTypes[1] : res.serviceTypes[0];

            const calc = new QuickPriceCalculator();
            calc.hydrate(res.quickPriceDehydrate);
            setQuickPriceCalculator(calc);

            const availability = new Availability();
            availability.hydrate(res.availabilityDehydrate);
            setAvailabilityChecker(availability);

            let newStartDate: Date = formState.start;
            if (!calc.priceListSelector?.inValidRange(newStartDate)) {
              if (calc.priceListSelector?.getValidity().validFrom) {
                newStartDate = calc.priceListSelector?.getValidity().validFrom || formState.start;
              }
            }

            setFormState({
              ...formState,
              start: newStartDate,
              end: addDays(1, newStartDate),
              service,
              days: formState.days.map(day => ({
                ...day,
                ..._initDailyAddons(res.serviceTypes, service),
              })),
            });
            onChange([newStartDate, addDays(1, newStartDate)]);
          } else {
            setServiceTypesError('Laden der Seminarbuchungskonfiguration fehlgeschlagen');
          }
        })
        .catch(err => {
          console.error(err);
          setServiceTypesError('Laden der Seminarbuchungskonfiguration fehlgeschlagen');
        });
    }
  }, []);

  const _mapFormStateToDownloadInput = () => {
    return {
      hotelRef: hotelRef!,
      ..._mapFormStateToQuickPrice(),
      client: {
        firstname: formState.firstname,
        lastname: formState.lastname,
        email: formState.email,
        company: formState.company,
        country: formState.country,
        phone: formState.phone,
        address: formState.address,
        city: formState.city,
        zip: formState.zip,
        billingCompany: formState.billingCompany,
        billingFirstname: formState.billingFirstname,
        billingLastname: formState.billingLastname,
        billingAddress: formState.billingAddress,
        billingCity: formState.billingCity,
        billingZip: formState.billingZip,
        billingCountry: formState.billingCountry,
      },
    };
  };

  const _mapFormStateToQuickPrice = () => {
    return {
      language: 'de',
      startDate: formState.start,
      endDate: formState.end,
      days: formState.days.map(day => ({
        ...day,
        overnightGuests: noRooms ? 0 : day.overnightGuests,
        addonProducts: day.addonProducts
          .filter(product => product.visible && product.checked)
          .map(product => ({
            lookupCode: product.lookupCode,
            sku: product.sku,
            count: product.count,
          })),
        addonFacilities: day.addonFacilities
          .filter(facility => facility.visible && facility.checked)
          .map(facility => ({
            lookupCode: facility.lookupCode,
            sku: facility.sku,
            count: facility.count,
          })),
      })),
      prevdayGuests: noRooms ? 0 : formState.prevdayGuests,
      serviceTypeSku: formState.service.sku,
      comment: formState.comment,
    };
  };

  useEffect(() => {
    if (formState.start && formState.end && hotelRef && formState.service.sku) {
      setTotalPriceNet(null);
      setTotalPriceGross(null);

      if (quickPriceCalculator) {
        try {
          const res = quickPriceCalculator.calculatePrice(_mapFormStateToQuickPrice());
          setTotalPriceNet(res.totalPriceNet.toFixed(2));
          setTotalPriceGross(res.totalPriceGross.toFixed(2));
        } catch (err) {
          console.log(err);
          setServiceTypesError('Preisberechnung fehlgeschlagen');
        }
      }
    }
  }, [formState]);

  useEffect(() => {
    setFormState({
      ...formState,
      days: formState.days.map(day => ({
        ...day,
        addonProducts: _serviceTypeApplyAddonProductsVisibility(formState.service, day.addonProducts),
        addonFacilities: _serviceTypeApplyAddonFacilitiesVisibility(formState.service, day.addonFacilities),
      })),
    });
  }, [formState.service]);

  useEffect(() => {
    if (prevFormStateRef.current.meetingRooms > formState.meetingRooms) {
      setFormState({
        ...formState,
        days: formState.days.map(day => ({
          ...day,
          occupancy: day.occupancy.slice(0, formState.meetingRooms),
        })),
      });
    } else if (prevFormStateRef.current.meetingRooms < formState.meetingRooms) {
      setFormState({
        ...formState,
        days: formState.days.map(day => ({
          ...day,
          occupancy: [...day.occupancy, ...Array.from({ length: formState.meetingRooms - day.occupancy.length }, (_, i) => 'FULLDAY')],
        })),
      });
    }
  }, [formState.meetingRooms]);

  useEffect(() => {
    prevFormStateRef.current = JSON.parse(JSON.stringify(formState));
  }, [formState]);

  const [capsuleWrapperPositionLeft, setCapsuleWrapperPositionLeft] = useState<number | undefined>(undefined);
  const [capsuleWrapperPositionTop, setCapsuleWrapperPositionTop] = useState<number | undefined>(undefined);
  const [capsuleWrapperWidth, setCapsuleWrapperWidth] = useState<number | undefined>(undefined);
  const capsuleWrapperRef = React.useRef<HTMLDivElement>(null);

  const [widgetClickedOnce, setWidgetClickedOnce] = useState(false);

  function handleResize() {
    setCapsuleWrapperPositionLeft(capsuleWrapperRef.current?.getBoundingClientRect().left);
    setCapsuleWrapperPositionTop(
      (capsuleWrapperRef.current?.getBoundingClientRect().top || 0) +
        (capsuleWrapperRef.current?.getBoundingClientRect().height || 0) +
        window.scrollY,
    );
    setCapsuleWrapperWidth(capsuleWrapperRef.current?.getBoundingClientRect().width);
  }

  useLayoutEffect(() => {
    handleResize();
    window.addEventListener('resize', handleResize);
    setInterval(() => {
      handleResize();
    }, 100);
  }, []);

  useEffect(() => {
    if (popupShow) {
      OtelManager.getInstance().widgetSetCurrentView('tab-' + popupShow);
    } else {
      OtelManager.getInstance().widgetSetCurrentView('main');
    }
  }, [popupShow]);

  useEffect(() => {
    if (drawerOpen) {
      OtelManager.getInstance().widgetSetCurrentView('drawer');
    }
  }, [drawerOpen]);

  useEffect(() => {
    OtelManager.getInstance().widgetEventCounterInc('widgetLoaded');
  }, []);

  const isDisabled = serviceTypes.length === 0;
  const showError = !hotelRef ? 'Fehlende Konfiguration' : serviceTypesError ? serviceTypesError : null;

  return (
    <>
      {popupShow === 'termin' && (
        <div
          style={{
            borderTopLeftRadius: '32px',
            borderTopRightRadius: '32px',
            position: 'absolute',
            width: capsuleWrapperWidth,
            marginTop: semPlan === 'STARTER' || semPlan === 'BUSINESS' ? 34 : 0,
            backgroundColor: 'white',
            height: (capsuleWrapperRef.current?.getBoundingClientRect().height || 0) + 'px',
          }}
        ></div>
      )}
      <div
        ref={capsuleWrapperRef}
        style={{ position: 'relative' }}
        onClick={() => {
          OtelManager.getInstance().widgetEventCounterInc('widgetClicked');
          if (!widgetClickedOnce) {
            OtelManager.getInstance().widgetEventCounterInc('widgetClickedOnce');
            setWidgetClickedOnce(true);
          }
        }}
      >
        {semPlan === 'STARTER' || semPlan === 'BUSINESS' ? (
          <div style={{ marginLeft: (capsuleWrapperWidth || 0) - 300 }}>
            <CapsuleAd subscription="" hidden={!expanded}>
              Powered by{' '}
              <a href="https://www.seminargo.com" target="_blank" style={{ color: '#ac2a6e', fontWeight: 'bold' }}>
                seminargo
              </a>
            </CapsuleAd>
          </div>
        ) : null}
        {showError && (
          <BaseCapsule className="SeminargoCapsule" styles={styles} expanded={expanded} slimMode={isSlimMode() || false}>
            <CapsuleTab name="error" setPopupShow={setPopupShow} label="Fehler" icon="chevronright" description={showError} disabled error />
          </BaseCapsule>
        )}
        {!showError && (
          <>
            <BaseCapsule
              className="SeminargoCapsule"
              styles={styles}
              expanded={expanded}
              slimMode={isSlimMode() || false}
              onClick={() => (isSlimMode() ? setDrawerOpen(true) : setExpanded(true))}
            >
              {!expanded && (
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <Icon name="meeting_room" /> {label}
                </div>
              )}
              {expanded && (
                <>
                  <CapsuleTabContainer role="tablist">
                    <CapsuleTab
                      name="termin"
                      ariaLabel={`Termin: ${(formState.start && formState.end && format('dd.MM.yyyy', formState.start) + ' bis ' + format('dd.MM.yyyy', formState.end)) || 'keine'}`}
                      label="Termin"
                      icon="calendar"
                      description={
                        (formState.start && formState.end && format('dd.MM.', formState.start) + ' - ' + format('dd.MM.yy', formState.end)) || 'keine'
                      }
                      popupShow={popupShow}
                      expanded={popupExpanded}
                      disabled={isDisabled}
                      setPopupShow={setPopupShow}
                      capsuleLeftPosition={capsuleWrapperPositionLeft}
                      capsuleTopPosition={capsuleWrapperPositionTop}
                      capsuleWidth={capsuleWrapperWidth}
                      popupContent={
                        <CalendarSection
                          availabilityChecker={availabilityChecker}
                          quickPriceCalculator={quickPriceCalculator}
                          vertical={false}
                          fullScreen={false}
                          drawer={false}
                          onCalChanged={onCalChanged}
                          calValue={calValue}
                        />
                      }
                    />
                    <CapsuleTab
                      icon="meeting_room"
                      name="meeting_rooms"
                      label="Seminarräume"
                      ariaLabel={`Seminarräume: min. ${
                        formState.days
                          .map(day => day.occupancy.length)
                          .reduce((a, b) => Math.min(a, b), 1000)
                          .toString() +
                        ' / max. ' +
                        formState.days
                          .map(day => day.occupancy.length)
                          .reduce((a, b) => Math.max(a, b), 0)
                          .toString()
                      }`}
                      tooltip="Seminarräume"
                      description={
                        formState.days
                          .map(day => day.occupancy.length)
                          .reduce((a, b) => Math.min(a, b), 1000)
                          .toString() +
                        ' / ' +
                        formState.days
                          .map(day => day.occupancy.length)
                          .reduce((a, b) => Math.max(a, b), 0)
                          .toString()
                      }
                      popupShow={popupShow}
                      setPopupShow={setPopupShow}
                      expanded={popupExpanded}
                      disabled={isDisabled}
                      capsuleLeftPosition={capsuleWrapperPositionLeft}
                      capsuleTopPosition={capsuleWrapperPositionTop}
                      capsuleWidth={capsuleWrapperWidth}
                      popupContent={
                        <MeetingRoomsSectionPopup
                          setFormState={setFormState}
                          formState={formState}
                          meetingRoomsDay={meetingRoomsDay}
                          setMeetingRoomsDay={setMeetingRoomsDay}
                          shadowRoot={shadowRoot}
                          availabilityChecker={availabilityChecker}
                        />
                      }
                    />
                    <CapsuleTab
                      icon="people"
                      name="particiants"
                      label="Gäste"
                      ariaLabel={
                        `Gäste: min.` +
                        Math.min(...formState.days.map(v => v.totalGuests)).toString() +
                        ' / max. ' +
                        Math.max(...formState.days.map(v => v.totalGuests)).toString()
                      }
                      description={
                        Math.min(...formState.days.map(v => v.totalGuests)).toString() +
                        ' / ' +
                        Math.max(...formState.days.map(v => v.totalGuests)).toString()
                      }
                      setPopupShow={setPopupShow}
                      popupShow={popupShow}
                      expanded={popupExpanded}
                      disabled={isDisabled}
                      capsuleLeftPosition={capsuleWrapperPositionLeft}
                      capsuleTopPosition={capsuleWrapperPositionTop}
                      capsuleWidth={capsuleWrapperWidth}
                      popupContent={<GuestSection setFormState={setFormState} formState={formState} />}
                    />
                    {!noRooms && (
                      <CapsuleTab
                        name="rooms"
                        label="Zimmer"
                        ariaLabel={
                          `Zimmer: min. ${Math.min(...formState.days.map(v => v.overnightGuests), formState.prevdayGuests)} / max. ${Math.max(
                            ...formState.days.map(v => v.overnightGuests),
                            formState.prevdayGuests,
                          )}` || 'nicht gesetzt'
                        }
                        icon="doublebed"
                        description={
                          `${Math.min(...formState.days.map(v => v.overnightGuests), formState.prevdayGuests)} / ${Math.max(
                            ...formState.days.map(v => v.overnightGuests),
                            formState.prevdayGuests,
                          )}` || 'nicht gesetzt'
                        }
                        setPopupShow={setPopupShow}
                        popupShow={popupShow}
                        expanded={popupExpanded}
                        disabled={isDisabled}
                        capsuleLeftPosition={capsuleWrapperPositionLeft}
                        capsuleTopPosition={capsuleWrapperPositionTop}
                        capsuleWidth={capsuleWrapperWidth}
                        popupContent={<RoomsSection setFormState={setFormState} formState={formState} />}
                      />
                    )}
                    <CapsuleTab
                      name="package"
                      label="Paket"
                      ariaLabel={`Paket: ${formState.service?.headerShort || formState.service?.header}`}
                      icon="coffee"
                      description={formState.service?.headerShort || formState.service?.header || '...'}
                      setPopupShow={setPopupShow}
                      popupShow={popupShow}
                      expanded={popupExpanded}
                      disabled={isDisabled}
                      capsuleLeftPosition={capsuleWrapperPositionLeft}
                      capsuleTopPosition={capsuleWrapperPositionTop}
                      capsuleWidth={capsuleWrapperWidth}
                      popupContent={
                        <ServiceSection
                          quickPriceCalculator={quickPriceCalculator}
                          setFormState={setFormState}
                          serviceTypes={serviceTypes}
                          formState={formState}
                        />
                      }
                    />
                    <div
                      style={{
                        width: '100%',
                        display: 'flex',
                        justifyContent: 'flex-end',
                        marginRight: '20px',
                      }}
                    >
                      <div style={{ paddingTop: 8, paddingRight: 10 }}>
                        {totalPriceGross && (
                          <>
                            <div>
                              {(totalPriceNet || 0)
                                .toString()
                                .replace('.', ',')
                                .replace(/\B(?=(\d{3})+(?!\d))/g, '.')}{' '}
                              €
                            </div>
                            <div style={{ fontSize: 10 }}>+ MwSt.</div>
                          </>
                        )}
                        {(!totalPriceGross || isDisabled) && (
                          <div style={{ paddingTop: 20, paddingLeft: 20 }}>
                            <Spinner />
                          </div>
                        )}
                      </div>

                      <Button
                        color={color}
                        filled
                        aria-label="Weiter zur Buchung"
                        iconPosition="right"
                        disabled={isDisabled || totalPriceNet === "0.00" || totalPriceGross === "0.00"}
                        onClick={() => {
                          OtelManager.getInstance().nextButtonClickCounterInc();
                          setPopupShow('form');
                        }}
                      >
                        Weiter
                        <Icon name="chevronright" color="white" />
                      </Button>
                    </div>
                  </CapsuleTabContainer>
                </>
              )}
            </BaseCapsule>
            {popupShow !== null && <ClickOverlay open={true} onClick={() => setPopupShow(null)} />}
            {popupShow === 'form' && (
              <BookingForm
                formState={formState}
                setFormState={setFormState}
                totalPriceGross={totalPriceGross}
                totalPriceNet={totalPriceNet}
                setTermsAccepted={setTermsAccepted}
                termsAccepted={termsAccepted}
                privacyPolicyUrl={privacyPolicyUrl}
                termsUrl={termsUrl}
                reservationStatus={reservationStatus}
                setReservationStatus={setReservationStatus}
                emailVerificationCode={emailVerificationCode}
                setEmailVerificationCode={setEmailVerificationCode}
                emailVerificationCodeInvalid={emailVerificationCodeInvalid}
                setEmailVerificationCodeInvalid={setEmailVerificationCodeInvalid}
                errors={errors}
                setErrors={setErrors}
                hotelRef={hotelRef}
                setPopupShow={setPopupShow}
                setDrawerOpen={setDrawerOpen}
                meetingRoomsDay={meetingRoomsDay}
                isMobileSize={isMobileSize}
                noRooms={noRooms}
                placeReservation={placeReservation}
                color={color}
                ValidateEmail={ValidateEmail}
                verifyReservationEmail={verifyReservationEmail}
                downloadUrl={getDownloadUrl(_mapFormStateToDownloadInput())}
                hotelName={hotelName}
                quickPriceCalculator={quickPriceCalculator}
              />
            )}
            <Drawer
              open={drawerOpen}
              onOverlayClick={() => setDrawerOpen(false)}
              fullscreen={isMobileSize()}
              bottomContent={
                <div
                  style={{
                    display: 'flex',
                    fontFamily: 'Roboto, sans-serif',
                    alignContent: 'center',
                    justifyContent: 'right',
                  }}
                >
                  {totalPriceGross && (
                    <>
                      <a
                        style={{
                          display: 'flex',
                          alignSelf: 'center',
                          flexDirection: 'column',
                          padding: 10,
                          cursor: 'pointer',
                        }}
                        onClick={() => {
                          OtelManager.getInstance().downloadPdfClickCounterInc(formState);
                          window.open(getDownloadUrl(_mapFormStateToDownloadInput()), '_blank');
                        }}
                      >
                        <Icon name="download" color={color} />
                      </a>
                      <div
                        style={{
                          display: 'flex',
                          alignSelf: 'center',
                          flexDirection: 'column',
                          paddingRight: 10,
                        }}
                      >
                        <div>
                          <strong>
                            {(totalPriceNet || 0)
                              .toString()
                              .replace('.', ',')
                              .replace(/\B(?=(\d{3})+(?!\d))/g, '.')}
                            €
                          </strong>
                        </div>
                        <div style={{ fontSize: 10 }}>+ MwSt.</div>
                      </div>
                      <Button
                        color={color}
                        filled
                        iconPosition="right"
                        onClick={() => {
                          if (isMobileSize() || isSlimMode()) {
                            setDrawerOpen(false);
                            setPopupShow('form');
                          } else {
                            setDrawerOpen(false);
                            setPopupShow('form');
                          }
                        }}
                      >
                        Weiter
                        <Icon name="chevron-right" color={color} />
                      </Button>
                    </>
                  )}
                  {!totalPriceGross && (
                    <div style={{ padding: 20 }}>
                      <Spinner />
                    </div>
                  )}
                </div>
              }
            >
              <div
                style={{
                  borderBottom: '1px solid #eef4fc',
                  padding: '10px 20px',
                }}
              >
                <div
                  style={{
                    cursor: 'pointer',
                    float: 'right',
                    fontFamily: 'Roboto, sans-serif',
                    marginRight: 10,
                    marginTop: 14,
                  }}
                  onClick={() => setDrawerOpen(false)}
                >
                  X
                </div>
                <h1 style={{ fontFamily: 'Roboto, sans-serif', fontSize: 16 }}>Ihre Wunschkonfiguration</h1>
              </div>

              <DrawerAccordion
                defaultValue={isMobileSize() || isSlimMode() ? null : 'service'}
                values={[
                  {
                    name: 'termin',
                    label: 'Termin',
                    description:
                      (formState.start && formState.end && format('dd.MM.', formState.start) + ' - ' + format('dd.MM.yy', formState.end)) || 'keine',
                    content: (
                      <>
                        <CalendarSection
                          quickPriceCalculator={quickPriceCalculator}
                          availabilityChecker={availabilityChecker}
                          fullScreen={isMobileSize()}
                          vertical={true}
                          drawer={true}
                          onCalChanged={onCalChanged}
                          calValue={calValue}
                        />
                      </>
                    ),
                  },
                  {
                    name: 'meetingRooms',
                    label: 'Seminarräume',
                    description: formState.meetingRooms.toString(),
                    content: (
                      <MeetingRoomsSectionDrawer
                        setFormState={setFormState}
                        formState={formState}
                        meetingRoomsDay={meetingRoomsDay}
                        setMeetingRoomsDay={setMeetingRoomsDay}
                        shadowRoot={shadowRoot}
                        availabilityChecker={availabilityChecker}
                      />
                    ),
                  },
                  {
                    name: 'guests',
                    label: 'Gäste',
                    description:
                      Math.min(...formState.days.map(v => v.totalGuests)).toString() +
                      ' / ' +
                      Math.max(...formState.days.map(v => v.totalGuests)).toString(),
                    content: <GuestSection formState={formState} setFormState={setFormState} />,
                  },
                  ...(noRooms
                    ? []
                    : [
                        {
                          name: 'rooms',
                          label: 'Zimmer',
                          description:
                            `${Math.min(...formState.days.map(v => v.overnightGuests), formState.prevdayGuests)} / ${Math.max(
                              ...formState.days.map(v => v.overnightGuests),
                              formState.prevdayGuests,
                            )}` || 'nicht gesetzt',

                          content: <RoomsSection setFormState={setFormState} formState={formState} />,
                        },
                      ]),
                  {
                    name: 'service',
                    label: 'Service',
                    icons: ['flipchart', 'coffee'],
                    description: formState.service?.headerShort || formState.service?.header,
                    content: (
                      <ServiceSection
                        quickPriceCalculator={quickPriceCalculator}
                        setFormState={setFormState}
                        serviceTypes={serviceTypes}
                        formState={formState}
                      />
                    ),
                  },
                ]}
              />
            </Drawer>
          </>
        )}
      </div>
    </>
  );
};

export default Capsule;
