import { isAfter, isEqual, isBefore, parseISO } from 'date-fns/fp';
import Calendar from 'react-calendar';
import { CalendarContainer, CapsuleTabContentBody } from '../CapsuleComponents';
import { createRef, useEffect, useRef, useState } from 'react';
import { QuickPriceCalculator, QuickPriceCalculatorType } from '../../../semshared/pricelist/quickprice';
import { AvailabilityType } from '../../../semshared/availability/availability';

interface CalendarSectionProps {
  onCalChanged: any;
  calValue: any;
  fullScreen: boolean;
  drawer: boolean;
  vertical: boolean;
  quickPriceCalculator: QuickPriceCalculatorType | null;
  availabilityChecker: AvailabilityType | null;
}

const CalendarSection = ({
  onCalChanged,
  calValue,
  fullScreen,
  drawer,
  vertical,
  quickPriceCalculator,
  availabilityChecker,
}: CalendarSectionProps) => {
  const ref = useRef<any>();

  const updateTooltips = () => {
    setTimeout(() => {
      ref &&
        ref.current &&
        ref.current.querySelectorAll('.no-price').forEach((item: any, key: any) => {
          item.setAttribute('title', 'Das Hotel hat für diesen Tag noch keinen Preis festgelegt');
        });
      ref &&
        ref.current &&
        ref.current.querySelectorAll('.no-rooms-left').forEach((item: any, key: any) => {
          item.setAttribute('title', 'Das Hotel hat für diesen Tag keine Seminarräume mehr verfügbar');
        });
      ref &&
        ref.current &&
        ref.current.querySelectorAll('.react-calendar__tile').forEach((item: any, key: any) => {
          item.setAttribute('aria-label', item.childNodes[0].getAttribute('aria-label'));
        });
    }, 100);
  };

  useEffect(() => {
    updateTooltips();
  }, []);

  const getDisabledRanges = (date: any) => {
    if (isBefore(new Date(), date)) {
      return true;
    }
    if (quickPriceCalculator) {
      if (!quickPriceCalculator.priceListSelector?.inValidRange(date)) {
        return true;
      }
    }
    if (availabilityChecker) {
      if (!availabilityChecker.areMeetingRoomsAvailable(1, date)) {
        return true;
      }
    }
    return false;
  };

  const [today, setToday] = useState(new Date());
  return (
    <CapsuleTabContentBody>
      <CalendarContainer vertical={vertical} fullScreen={fullScreen} drawer={drawer}>
        <Calendar
          inputRef={ref}
          locale="de-DE"
          onChange={onCalChanged}
          showWeekNumbers
          value={calValue}
          selectRange={true}
          showDoubleView={true}
          showFixedNumberOfWeeks={false}
          showNeighboringMonth={false}
          nextAriaLabel="Nächster Monat"
          next2AriaLabel="Nächstes Jahr"
          prev2AriaLabel="Vorheriges Jahr"
          prevAriaLabel="Vorheriger Monat"
          navigationAriaLabel="Kalenderansicht ändern"
          tileDisabled={({ date }) => getDisabledRanges(date)}
          tileClassName={({ date, view }) => {
            if (view === 'month') {
              if (quickPriceCalculator && !quickPriceCalculator.priceListSelector?.inValidRange(date)) {
                return 'no-price';
              }
              if (availabilityChecker && !availabilityChecker.areMeetingRoomsAvailable(1, date)) {
                return 'no-rooms-left';
              }
            }
          }}
          // fixes the issue to jump to next month when clicking on next month
          activeStartDate={today} // pass in state var in activeStartDate
          onActiveStartDateChange={data => {
            const { value, activeStartDate, action } = data;
            updateTooltips();
            if (action === 'next' || action === 'next2') {
              setToday(new Date(activeStartDate as Date));
            }
            if (action === 'prev' || action === 'prev2') {
              setToday(new Date(activeStartDate as Date));
            }
          }}
        />
      </CalendarContainer>
    </CapsuleTabContentBody>
  );
};

export default CalendarSection;
