import Divider from '../../Divider';
import NumField from '../../NumField';
import { format, addDays } from 'date-fns/fp';
import { CapsuleTabContentBody, CapsuleTabContentHeader, ContentRow } from '../CapsuleComponents';
import { IFormState } from '../Capsule';

interface GuestSectionProps {
  formState: IFormState;
  setFormState: (formState: IFormState) => void;
}

const GuestSection = ({ formState, setFormState }: GuestSectionProps) => (
  <>
    <CapsuleTabContentHeader>Bitte geben Sie die Teilnehmeranzahl pro Tag an.</CapsuleTabContentHeader>
    <CapsuleTabContentBody>
      <ContentRow>
        {formState.start &&
          [...Array(formState.days.length)].map((_, index) => (
            <div key={`particants_tab_${index}`}>
              <div
                style={{
                  display: 'flex',
                  fontFamily: 'Roboto, sans-serif',
                  justifyContent: 'space-between',
                }}
              >
                <div
                  style={{
                    minWidth: 50,
                    paddingRight: 40,
                    textAlign: 'left',
                  }}
                >
                  <>
                    <strong>Tag {index + 1}</strong>
                    <div>{format('dd.MM.yyyy', addDays(index, formState.start || new Date()))}</div>
                  </>
                </div>
                <div
                  style={{
                    paddingRight: 0,
                    alignSelf: 'center',
                  }}
                >
                  <NumField
                    ariaLabelPrefix={`Tag ${index + 1} - Gast`}
                    value={formState.days[index].totalGuests}
                    onChange={value => {
                      setFormState({
                        ...formState,
                        days: formState.days.map((day, i) => {
                          if (index === i) {
                            return {
                              ...day,
                              totalGuests: value,
                            };
                          } else {
                            return day;
                          }
                        }),
                      });
                    }}
                  />
                </div>
              </div>
              <Divider />
            </div>
          ))}
      </ContentRow>
    </CapsuleTabContentBody>
  </>
);
export default GuestSection;
