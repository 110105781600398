import React from 'react';
import styled from 'styled-components';

const BaseDivider = styled.div`
  width: 100%;
  border-bottom: 1px solid #abadae;
  margin: 15px 0;
`;

const Divider = () => {
  return (
    <>
      <BaseDivider />
    </>
  );
};

export default Divider;
