import React from 'react';
import styled, { ThemedStyledProps, css } from 'styled-components';
import { lighten, modularScale } from 'polished';

const Button = styled.button<{
  filled?: boolean;
  color: string;
  iconPosition?: string;
  fullWidth?: boolean;
  disabled?: boolean;
}>`
  background-color: ${props => (props.filled ? (props.disabled ? 'lightgray' : props.color) : '#fff')};
  border-radius: 32px;
  border-width: 3px;
  border-color: ${props => (props.disabled ? 'lightgray' : props.color)};
  border-style: solid;
  color: ${props => (props.filled ? '#fff' : props.color)};
  padding: 10px 20px;
  font-size: 16px;
  ${props => (props.disabled ? '' : 'cursor: pointer;')}
  font-family: Roboto, sans-serif;
  justify-content: flex-end;
  width: ${props => (props.fullWidth ? '100%' : 'auto')};
  &:has(> svg) {
    ${props => (props.iconPosition === 'right' ? 'padding-right: 10px' : '')};
    ${props => (props.iconPosition === 'left' ? 'padding-left: 10px' : '')};
  }
  & > svg {
    height: 12px;
  }
  &:hover > svg > path {
    stroke: ${props => (props.filled ? props.color : '#fff')};
  }
  ${props =>
    props.disabled
      ? ''
      : `
    &:hover, &:focus {
      background-color: ${props.filled ? '#fff' : props.color};
      color: ${props.filled ? props.color : '#fff'};
    }
  `}
`;

export default Button;
