import React from 'react';
import styled from 'styled-components';

const BaseInput = styled.input`
  position: relative;
  left: 15px;
  top: -4px;
  z-index: 0;
  -webkit-appearance: none;
  & + label:before {
    width: 15px;
    height: 15px;
    border-radius: 10px;
    border: 2px solid #000;
    background-color: #fff;
    display: block;
    content: '';
    float: left;
    margin-right: 5px;
    z-index: 5;
    cursor: pointer;
  }
  &:checked + label:before {
    box-shadow: inset 0px 0px 0px 3px #fff;
    background-color: #000;
  }
  & + label {
    cursor: pointer;
    display: inline-block;
  }
`;

const Radio = (inputProps: any) => {
  return (
    <div>
      <BaseInput type="radio" id={inputProps.id} {...inputProps} />
      <label htmlFor={inputProps.id}>{inputProps.label}</label>
    </div>
  );
};

export default Radio;
