import React from 'react';
import styled from 'styled-components';

const BaseTable = styled.table`
  width: 100%;
`;

const BaseTableHead = styled.thead``;

const BaseTableBody = styled.tbody`
  & > tr:first-child td {
    border-top: 1px solid #abadae;
  }
  text-align: center;
`;

const BaseTableRow = styled.tr``;

const BaseTableHeaderCell = styled.td<{ vertical: boolean; centered: boolean }>`
  & > span {
    display: inline-block;
    width: ${props => (props.vertical ? '50px' : '100%')};
    padding: 10px;
    transform: ${props => (props.vertical ? 'rotate(-90deg)' : 'rotate(0deg)')};
    margin-bottom: ${props => (props.vertical ? '30px' : '0px')};
    margin-top: ${props => (props.vertical ? '50px' : '0px')};
    text-align: ${props => (props.centered ? 'center' : 'left')};
  }
`;

const BaseTableCell = styled.td`
  padding: 10px;
  border-bottom: 1px solid #abadae;
  margin: 15px 0;
`;

interface SimpleTableProps {
  header: any[];
  data: any[][];
  verticalHeader: boolean;
  centeredHeader?: boolean;
  name: string;
}

const SimpleTable = ({ header, data, verticalHeader, centeredHeader, name }: SimpleTableProps) => {
  return (
    <BaseTable cellPadding={0} cellSpacing={0}>
      <BaseTableHead>
        <BaseTableRow>
          {header.map((item, index) => (
            <BaseTableHeaderCell key={`${name}_header_${index}`} vertical={verticalHeader} centered={centeredHeader || false}>
              <span>{item}</span>
            </BaseTableHeaderCell>
          ))}
        </BaseTableRow>
      </BaseTableHead>
      <BaseTableBody>
        {data.map((row, index) => (
          <BaseTableRow key={`${name}_row_${index}`}>
            {row.map((item, index) => (
              <BaseTableCell key={`${name}_col_${index}`}>{item}</BaseTableCell>
            ))}
          </BaseTableRow>
        ))}
      </BaseTableBody>
    </BaseTable>
  );
};

export default SimpleTable;
