import React from 'react';
import { CapsuleTabContentBody } from '../CapsuleComponents';
import { IFormState } from '../Capsule';
import ServiceAccordion from '../../ServiceAccordion';
import ExtrasSection, { hasFormStateAnyVisibleExtras } from './ExtrasSection';
import { QuickPriceCalculatorType } from '../../../semshared/pricelist/quickprice';

interface ServiceSectionProps {
  setFormState: (formState: any) => void;
  formState: IFormState;
  serviceTypes: any[];
  quickPriceCalculator: QuickPriceCalculatorType | null;
}

const ServiceSection = ({ setFormState, formState, serviceTypes, quickPriceCalculator }: ServiceSectionProps) => {
  return (
    <>
      {serviceTypes && (
        <ServiceAccordion
          onChange={value => {
            setFormState({
              ...formState,
              service: value,
            });
          }}
          defaultValue={formState.service}
          values={serviceTypes.map((serviceType: any) => ({
            objValue: serviceType,
            value: serviceType.sku,
            content: serviceType.details,
          }))}
        />
      )}
    </>
  );
};

export default ServiceSection;
