import React, { useEffect, useRef } from 'react';
import styled, { ThemedStyledProps, css } from 'styled-components';
import Icon from './Icon';

interface DropDownProps {
  options: Option[];
  onChange?: (val: any) => void;
  value: string;
  shadowRoot: any;
  popupPosition: string;
  ariaLabel: string;
}

interface Option {
  label: string;
  value: string;
  icon?: any;
}

const Button = styled.button`
  background-color: transparent;
  border: 0px;
  cursor: pointer;
  display: flex;
  & > span {
    display: inline-block;
    font-size: 16px;
    align-self: center;
    white-space: nowrap;
  }
  & > svg {
    margin-left: 5px;
    width: 16px;
    align-self: center;
  }
  & > img {
    margin-left: 5px;
    margin-right: 5px;
    width: 16px;
    align-self: center;
  }
`;

const Container = styled.div`
  position: relative;
  width: 100%;
  display: flex;
  justify-content: flex-end;
`;

const UlStyled = styled.ul`
  position: absolute;
  list-style-type: none;
  margin: -60px -20px;
  padding: 0;
  z-index: 8000;

  border: 1px solid #999;
  cursor: pointer;

  & > li {
    text-align: left;
    padding: 3px 10px;
    font-size: 12px;
    display: flex;

    background-color: white;
    cursor: pointer;
    height: 26px;
  }

  & > li > svg {
    margin-right: 5px;
    align-self: center;
    width: 16px;
  }

  & > li > img {
    margin-right: 5px;
    align-self: center;
    width: 20px;
  }

  & > li > span {
    display: inline-block;
    align-self: center;
  }

  & > li:hover {
    background-color: lightgray;
  }
`;

const valToLabel = (options: Option[], val: string) => {
  const option = options.find(o => o.value === val);
  if (option) {
    return option.label;
  }
  return val;
};

const valToIcon = (options: Option[], val: string) => {
  const option = options.find(o => o.value === val);
  if (option && option.icon) {
    return option.icon;
  }
  if (options.find(o => o.icon)) {
    return <img src="/assets/icons/leer.png" />;
  }
  return null;
};

const Dropdown = ({ options, onChange, value, shadowRoot, popupPosition, ariaLabel }: DropDownProps) => {
  const [open, setOpen] = React.useState(false);
  const ulRef = useRef<HTMLUListElement>(null);
  const buttonRef = useRef<HTMLButtonElement>(null);

  useEffect(() => {
    if (ulRef && ulRef.current) {
      ulRef.current.style.marginTop = -1 * (ulRef.current.clientHeight - ulRef.current.clientHeight / 3) + 'px';
    }
  }, [open]);

  useEffect(() => {
    function handleClickOutside(event: any) {
      if (ulRef.current && !ulRef.current.contains(event.target)) {
        setOpen(false);
      }
    }
    // Bind the event listener
    shadowRoot.addEventListener('mousedown', handleClickOutside);
    ulRef.current?.focus();
    return () => {
      // Unbind the event listener on clean up
      shadowRoot.removeEventListener('mousedown', handleClickOutside);
      setTimeout(() => {
        buttonRef.current?.focus();
      }, 100);
    };
  }, [ulRef]);

  const handleOpen = () => {
    setOpen(!open);
  };

  return (
    <Container>
      <Button aria-label={ariaLabel} onClick={handleOpen} ref={buttonRef}>
        {valToIcon(options, value)}
        <span>{valToLabel(options, value)}</span> {!open && <Icon name="chevronup" />} {open && <Icon name="chevrondown" />}{' '}
      </Button>

      {open ? (
        <UlStyled ref={ulRef}>
          {options.map((menuItem, index) => (
            <li
              aria-label={menuItem.label}
              tabIndex={0}
              key={index}
              className="menu-item"
              onClick={() => {
                onChange ? onChange(menuItem.value) : null;
                buttonRef.current?.focus();
                setOpen(false);
              }}
              onKeyUp={e => {
                e.preventDefault();
                if (e.key === 'Enter') {
                  onChange ? onChange(menuItem.value) : null;
                  buttonRef.current?.focus();
                  setOpen(false);
                }
                if (e.key === 'Escape') {
                  buttonRef.current?.focus();
                  setOpen(false);
                }
              }}
            >
              {menuItem.icon} <span>{menuItem.label}</span>
            </li>
          ))}
        </UlStyled>
      ) : null}
    </Container>
  );
};

export default Dropdown;
