import moment from "moment";

interface IAvailability {
  validFrom: Date;
  validTo: Date;

  range: string;

  available: number;

  hotelId: number;
  facilityId: number | null;
  productId: number | null;
}


export type AvailabilityType = Availability<IAvailability>;

export class Availability<AvailabilityType extends IAvailability> {
  protected availabilityList: IAvailability[] | null = null;
  protected enabled: boolean = true;

  public hydrate(json: string) {
    const { availability, enabled } = JSON.parse(json);
    this.availabilityList = availability;
    this.enabled = enabled;
  }

  public dehydrate(): string {
    return JSON.stringify({
      availability: this.availabilityList,
      enabled: this.enabled
    });
  }

  private _getAvailabilityEntriesPerDay(date: Date): IAvailability[] {
    if (!this.availabilityList) return [];
    return this.availabilityList.filter((entry) => moment(entry.validFrom).startOf('day').isSameOrBefore(date) && moment(entry.validTo).endOf('day').isSameOrAfter(date));
  }

  public areMeetingRoomsAvailable(count: number, date: Date): boolean {
    if (!this.enabled) return true;
    const entries = this._getAvailabilityEntriesPerDay(date);
    const availableRooms = entries.reduce((acc, entry) => acc + entry.available, 0);
    return availableRooms >= count;
  }

  public getMaxMeetingRooms(date: Date): number {
    if (!this.enabled) return 1000;
    const entries = this._getAvailabilityEntriesPerDay(date);
    return entries.filter(entry => entry.facilityId !== null).reduce((acc, entry) => Math.max(acc, entry.available), 0);
  }

  public areRoomsAvailable(count: number, date: Date): boolean {
    // TODO: Implement this
    return true
  }


}