import React, { useEffect, useRef } from 'react';
import styled, { ThemedStyledProps, css } from 'styled-components';

const AddRemoveButton = styled.button<{ iconOnly: boolean }>`
  background-color: transparent;
  border-radius: 32px;
  border-width: 2px;
  border-color: #333;
  border-style: solid;
  width: ${props => (props.iconOnly ? '25px' : 'auto')};
  height: ${props => (props.iconOnly ? '25px' : 'auto')};
  padding: ${props => (props.iconOnly ? '0px' : '5px 10px')};
  color: #333;
  font-size: 16px;
  cursor: pointer;
  font-family: Roboto, sans-serif;
  text-align: center;
  text-weight: bold;
  &:hover,
  &:focus {
    border-width: 3px;
  }
`;

export default AddRemoveButton;
