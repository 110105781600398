import { WebTracerProvider } from '@opentelemetry/sdk-trace-web';
import { getWebAutoInstrumentations } from '@opentelemetry/auto-instrumentations-web';
import { UserInteractionInstrumentation } from '@opentelemetry/instrumentation-user-interaction';
import { OTLPTraceExporter } from '@opentelemetry/exporter-trace-otlp-http';
import { BatchSpanProcessor, SimpleSpanProcessor, ConsoleSpanExporter } from '@opentelemetry/sdk-trace-base';
import { registerInstrumentations } from '@opentelemetry/instrumentation';
import { ZoneContextManager } from '@opentelemetry/context-zone';
import { Counter, Span, SpanContext, SpanOptions, Tracer } from '@opentelemetry/api';
import { AggregationTemporalityPreference, DeltaTemporalitySelector } from '@opentelemetry/exporter-metrics-otlp-http';
import { IFormState } from '../components/capsule/Capsule';
const { Resource } = require('@opentelemetry/resources');
const { DiagConsoleLogger, DiagLogLevel, diag, metrics } = require('@opentelemetry/api');
const { OTLPMetricExporter } = require('@opentelemetry/exporter-metrics-otlp-http');
const { MeterProvider, PeriodicExportingMetricReader } = require('@opentelemetry/sdk-metrics');
const { SemanticResourceAttributes } = require('@opentelemetry/semantic-conventions');

export class OtelManager {
  private static _instance: OtelManager;

  private _rootSpan: Span | undefined;
  private _tracer: Tracer | undefined;
  private _meterProvider: any | undefined;
  private tabClickCounter: Counter | undefined;
  private widgetEventCounter: Counter | undefined;
  private nextButtonClickCounter: Counter | undefined;
  private downloadPdfClickCounter: Counter | undefined;
  private emailVerificationCounter: Counter | undefined;
  private _sessionId: string = '';

  private _attributes: { [key: string]: any } = {};

  private generateString = (length: number) => {
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    let result = ' ';
    const charactersLength = characters.length;
    for (let i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
  };

  public static getInstance() {
    if (!OtelManager._instance) {
      OtelManager._instance = new OtelManager();
      OtelManager._instance.init();
      this._instance._sessionId = this._instance.generateString(10);
    }

    return OtelManager._instance;
  }

  public get sessionId() {
    return this._sessionId;
  }

  public loadAttributes(attributes: { [key: string]: any }) {
    this._attributes = Object.assign({}, this._attributes, attributes);
  }

  public tabClickCounterInc(tabName: string) {
    this.tabClickCounter?.add(1, Object.assign({}, this._attributes, { tabName }));
  }

  public widgetSetCurrentView(viewName: string) {
    localStorage.setItem('sem-widget-current-view', viewName);
  }

  public widgetEventCounterInc(eventName: string) {
    const attributes = Object.assign({}, this._attributes, { eventName });
    if (eventName === 'widgetUnloaded') {
      const currentView = localStorage.getItem('sem-widget-current-view');
      if (currentView) {
        attributes['currentView'] = currentView;
      }
    }
    this.widgetEventCounter?.add(1, attributes);
  }

  public nextButtonClickCounterInc() {
    this.nextButtonClickCounter?.add(1, Object.assign({}, this._attributes));
  }

  public downloadPdfClickCounterInc(formState: IFormState) {
    this.downloadPdfClickCounter?.add(1, Object.assign({}, this._attributes, formState));
  }

  public emailVerificationCounterInc(status: string, formState: IFormState) {
    this.emailVerificationCounter?.add(1, Object.assign({}, this._attributes, { verificationStatus: status }, formState));
  }

  private initMetricsProvider() {
    this._meterProvider = new MeterProvider({
      resource: new Resource({
        [SemanticResourceAttributes.SERVICE_NAME]: 'sem-widget',
      }),
    });

    metrics.setGlobalMeterProvider(this._meterProvider);

    this._meterProvider.addMetricReader(
      new PeriodicExportingMetricReader({
        exporter: new OTLPMetricExporter({
          url: (process.env.REACT_APP_OTEL_ENDPOINT || 'http://localhost:4318') + '/v1/metrics',
          temporalityPreference: AggregationTemporalityPreference.DELTA,
        }),
        exportIntervalMillis: 10000,
      }),
    );

    let meter = this._meterProvider.getMeter('sem-collector');

    this.tabClickCounter = meter.createCounter('tabClickCounter', {
      description: 'Tab click counter',
    });

    this.widgetEventCounter = meter.createCounter('widgetEventCounter', {
      description: 'Widget event counter',
    });

    this.nextButtonClickCounter = meter.createCounter('nextButtonClickCounter', {
      description: 'Next button click counter',
    });

    this.downloadPdfClickCounter = meter.createCounter('downloadPdfClickCounter', {
      description: 'Download PDF button click counter',
    });

    this.emailVerificationCounter = meter.createCounter('emailVerificationCounter', {
      description: 'Email verification counter',
    });
  }

  private initTracingProvider() {
    const exporter = new OTLPTraceExporter({
      url: (process.env.REACT_APP_OTEL_ENDPOINT || 'http://localhost:4318') + '/v1/traces',
    });

    const provider = new WebTracerProvider({
      resource: new Resource({
        [SemanticResourceAttributes.SERVICE_NAME]: 'sem-widget',
      }),
    });
    provider.addSpanProcessor(new BatchSpanProcessor(exporter) as any);
    provider.addSpanProcessor(new SimpleSpanProcessor(new ConsoleSpanExporter()) as any);
    provider.register({
      contextManager: new ZoneContextManager(),
    });

    this._tracer = provider.getTracer('sem-widget-tracer');
    this._rootSpan = this._tracer.startSpan('sem-widget-root-span');

    addEventListener('beforeunload', async event => {
      this.widgetEventCounterInc('widgetUnloaded');
      this._rootSpan?.end();
      await this._meterProvider.forceFlush();
      await this._meterProvider.shutdown();
    });

    /*registerInstrumentations({
      instrumentations: [
        getWebAutoInstrumentations({
          // load custom configuration for xml-http-request instrumentation
          '@opentelemetry/instrumentation-xml-http-request': {
            propagateTraceHeaderCorsUrls: [
                /.+/g,
              ],
          },
          // load custom configuration for fetch instrumentation
          '@opentelemetry/instrumentation-fetch': {
            propagateTraceHeaderCorsUrls: [
                /.+/g,
              ],
          },
        }),
      ],
    });*/
  }

  private init() {
    diag.setLogger(new DiagConsoleLogger(), DiagLogLevel.DEBUG);

    this.initMetricsProvider();
    this.initTracingProvider();
  }
}
