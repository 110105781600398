import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import Icon from '../Icon';
import Tooltip from '../Tooltip';
import CapsulePopup from './CapsulePopup';
import { context, trace } from '@opentelemetry/api';
import { OtelManager } from '../../api/otel';

interface CapsulePopupInitProps {
  leftPosition: number | undefined;
  topPosition: number | undefined;
  show: string | null;
}

interface CapsuleTabProps {
  label: string;
  setPopupShow: (show: string | null) => void;
  onClick?: () => void;
  name: string;
  popupShow?: string | null;
  description?: string;
  styles?: string;
  icon?: string;
  expanded?: boolean;
  disabled?: boolean;
  error?: boolean;
  tooltip?: string;
  capsuleLeftPosition?: number;
  capsuleTopPosition?: number;
  capsuleWidth?: number;
  popupContent?: React.ReactNode;
  ariaLabel?: string;
}

const BaseCapsuleTab = styled.div<{
  focused?: boolean;
  disabled?: boolean;
  error?: boolean;
}>`
  background-color: ${props => (props.error ? '#ff0e0e' : props.focused && !props.disabled ? '#eff4fa' : '#fff')};
  background-color: ${props => (props.disabled ? '#f5f5f5' : '')};
  align-items: center;
  color: ${props => (props.error ? '#ffffff' : '#646a70')};
  padding: 15px 15px;
  font-size: 14px;
  ${props => (props.disabled ? '' : 'cursor: pointer;')}
  font-family: "Roboto", sans-serif;
  display: flex;
  text-align: left;
  box-sizing: border-box;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  ${props =>
    props.disabled
      ? ''
      : `&:hover, &:focus {
    background-color: #eff4fa;
  }`}
  & > svg {
    margin-right: 10px;
  }
`;

const Label = styled.div<{ error?: boolean }>`
  font-weight: bold;
  padding-bottom: 2px;
`;

const Description = styled.div<{ error?: boolean }>`
  font-size: 12px;
  color: ${props => (props.error ? '#ffffff' : '#444')};
  white-space: nowrap;
  max-width: 150px;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const CapsuleTab = ({
  label,
  description,
  name,
  popupShow,
  expanded,
  disabled,
  error,
  icon,
  onClick,
  setPopupShow,
  capsuleLeftPosition,
  capsuleTopPosition,
  capsuleWidth,
  popupContent,
  ariaLabel,
}: CapsuleTabProps) => {
  const ref = React.useRef<HTMLDivElement>(null);

  const [resizeTimeout, setResizeTimeout] = useState<any>(null);

  const [leftPosition, setLeftPosition] = useState<number | undefined>(undefined);
  const [topPosition, setTopPosition] = useState<number | undefined>(undefined);

  useEffect(() => {
    setLeftPosition(ref.current?.offsetLeft);
    setTopPosition(ref.current?.offsetTop);
    function handleResize() {
      clearTimeout(resizeTimeout);
      if (popupShow === name) {
        setResizeTimeout(
          setTimeout(() => {
            setLeftPosition(ref.current?.offsetLeft);
            setTopPosition(ref.current?.offsetTop);
          }, 10),
        );
      }
    }

    window.addEventListener('resize', handleResize);
  }, []);

  const _popupWidth = () => {
    if (name === 'termin') return `${capsuleWidth}px`;
    return 'auto';
  };

  const [popupSpan, setPopupSpan] = useState<any>(null);

  return (
    <>
      <BaseCapsuleTab
        role="tab"
        data-tooltip-id="tooltip"
        aria-label={ariaLabel}
        data-tooltip-content="Hello world!"
        className="SeminargoCapsuleTab"
        ref={ref}
        focused={popupShow === name}
        aria-selected={popupShow === name}
        disabled={disabled}
        error={error}
        tabIndex={0}
        onKeyUp={e => {
          if (e.key === 'Enter') {
            if (disabled) return;
            OtelManager.getInstance().tabClickCounterInc(name);
            /*context.with(trace.setSpan(context.active(), rootSpan), () => {
              rootSpan.addEvent('click', { tabName: name, type: 'CapsuleTab' });
              if (popupSpan) popupSpan.end();
              setPopupSpan(tracer.startSpan('CapsuleTabPopup-' + name));
            });*/
            onClick && onClick();
            setPopupShow && setPopupShow(popupShow === name ? null : name);
          }
        }}
        onClick={() => {
          if (disabled) return;
          OtelManager.getInstance().tabClickCounterInc(name);
          /*context.with(trace.setSpan(context.active(), rootSpan), () => {
            rootSpan.addEvent('click', { tabName: name, type: 'CapsuleTab' });
            if (popupSpan) popupSpan.end();
            setPopupSpan(tracer.startSpan('CapsuleTabPopup-' + name));
          });*/
          onClick && onClick();
          setPopupShow && setPopupShow(popupShow === name ? null : name);
        }}
      >
        <Icon name={icon} />

        <div>
          <Label error={error}>{label}</Label>
          <Description error={error}>{description}</Description>
        </div>
      </BaseCapsuleTab>
      {popupShow === name && (
        <CapsulePopup
          tabRef={ref}
          leftPosition={(leftPosition || 0) + (capsuleLeftPosition || 0)}
          topPosition={(topPosition || 0) + (capsuleTopPosition || 0)}
          expanded={expanded || true}
          width={_popupWidth()}
          covered={false}
          fullScreen={false}
          setPopupShow={setPopupShow}
        >
          {popupContent}
        </CapsulePopup>
      )}
    </>
  );
};

export default CapsuleTab;
