import React, { useEffect } from 'react';
import styled, { ThemedStyledProps, css } from 'styled-components';
import Icon from './Icon';
import { set } from 'date-fns';
import Accordion from './ServiceAccordion';

const AccordionBase = styled.div`
  width: 100%;
  box-sizing: border-box;
`;

const AccordionHeaderBase = styled.div`
  width: 100%;
  display: flex;
  padding: 20px 20px;
  box-sizing: border-box;
  border-radius: 10px;
  font-family: Roboto, sans-serif;
  align-items: center;
  cursor: pointer;
  border-bottom: 1px solid #eef4fc;
  user-select: none;
`;

const AccordionHeaderLabel = styled.div`
  width: 100%;
  font-weight: bold;
  font-size: 14px;
`;

const AccordionHeaderIcons = styled.div`
  display: flex;
  padding-left: 10px;
  width: 100px;
`;

const AccordionHeaderDescription = styled.div`
  font-weight: normal;
  font-size: 12px;
  color: #646a70;
  padding-top: 5px;
`;

const AccordionBodyBase = styled.div<{ selected?: boolean }>`
  overflow: hidden;
  font-family: Roboto, sans-serif;
  display: block;
  padding: 20px 20px;
  background-color: #fff;
  box-sizing: border-box;
  border-radius: 10px;
`;

const AccordionBodyContainer = styled.div<{ selected?: boolean }>`
  overflow: hidden;
  overflow-x: scroll;
  transition: max-height 0.1s;
  max-height: ${props => (props.selected ? 'auto' : '0px')};
  box-sizing: border-box;
  &::-webkit-scrollbar {
    width: 0px;
    background: transparent;
  }
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
`;

const AccordionHeaderChevron = styled.div`
  float: right;
`;

export interface AccordionItemProps {
  name: string;
  label: string;
  icons?: string[];
  content?: any;
  description?: string;
}

interface AccordionProps {
  values: AccordionItemProps[];
  onChange?: (value: AccordionItemProps) => void;
  defaultValue?: string;
}

const ConfirmationAccordion = ({ values, onChange }: AccordionProps) => {
  const [elValue, setElValue] = React.useState<string | null>(null);

  return (
    <>
      <AccordionBase className="SeminargoAccordion">
        {values.map((value, index) => (
          <div key={index} className="SeminargoAccordionItem">
            <AccordionHeaderBase
              tabIndex={0}
              className="SeminargoAccordionHeader"
              onKeyDown={e => {
                if (e.key === 'Enter') {
                  if (value.name === elValue) {
                    setElValue(null);
                    onChange &&
                      onChange({
                        name: '',
                        label: '',
                        icons: [],
                        content: null,
                      });
                  } else {
                    setElValue(value.name);
                    onChange && onChange(value);
                  }
                }
              }}
              onClick={() => {
                if (value.name === elValue) {
                  setElValue(null);
                  onChange &&
                    onChange({
                      name: '',
                      label: '',
                      icons: [],
                      content: null,
                    });
                } else {
                  setElValue(value.name);
                  onChange && onChange(value);
                }
              }}
            >
              <AccordionHeaderLabel className="SeminargoAccordionHeaderLabel">
                {value.label}
                <br />
                <AccordionHeaderDescription>{value.description}</AccordionHeaderDescription>
              </AccordionHeaderLabel>
              <AccordionHeaderChevron>
                {value.name === elValue && <Icon name="chevrondown" />}
                {value.name !== elValue && <Icon name="chevronup" />}
              </AccordionHeaderChevron>
            </AccordionHeaderBase>
            <AccordionBodyContainer selected={value.name === elValue}>
              <AccordionBodyBase>{value.content}</AccordionBodyBase>
            </AccordionBodyContainer>
          </div>
        ))}
      </AccordionBase>
    </>
  );
};

export default ConfirmationAccordion;
