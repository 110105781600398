import React, { useEffect } from 'react';
import styled, { ThemedStyledProps, css } from 'styled-components';

const BaseSpinnerContainer = styled.div`
  margin-left: -20px;
  margin-top: -20px;
  position: absolute;
  z-index: 19 !important;
  animation: loading-bar-spinner 800ms linear infinite;

  @keyframes loading-bar-spinner {
    0% {
      transform: rotate(0deg);
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
      transform: rotate(360deg);
    }
  }
`;

const BaseSpinner = styled.div`
  width: 20px;
  height: 20px;
  border: solid 2px transparent;
  border-top-color: #000 !important;
  border-left-color: #000 !important;
  border-radius: 50%;
`;

const spinner = () => {
  return (
    <BaseSpinnerContainer>
      <BaseSpinner />
    </BaseSpinnerContainer>
  );
};

export default spinner;
