import moment from 'moment';
import { toPricingWeekday } from './date';

export type PriceSelectorInput = {
  priceListId: number;
  bundleId?: number;
  bundleItemId?: number;
  productId?: number;
  facilityId?: number;
  guestCount: number;
  itemCount: number;
  date: Date;
  selectorId?: number;
};

export type PriceSelectorOutput<PriceSelectorType extends IPriceSelector> = {
  price: number | null;
  bundlePriceFromProduct: boolean | null;
  components: IProductPriceComponent[];
  selector: PriceSelectorType;
};

export interface IPriceList<PriceSelectorType extends IPriceSelector, PriceRuleType extends IPriceRule> {
  id: number;
  selectors: PriceSelectorType[];
  rules: PriceRuleType[];
  parent?: IPriceList<PriceSelectorType, PriceRuleType> | null;
}

export interface IPriceSelector {
  id: number;
  name: string;
  isBasePrice: boolean;
  minGuestCount: number | null;
  maxGuestCount: number | null;
  minItemCount: number | null;
  maxItemCount: number | null;
  validFrom: Date | null;
  validTo: Date | null;
  weekdays: IPriceSelectorWeekday[];
  prices: IProductPrice[];
}
export interface IPriceSelectorWeekday {
  weekdays: string;
}

export interface IPriceRule {
  id: number;
  rule: 'FIXED' | 'PERCENTAGE' | 'OVERWRITE';
  rate: number;
  rounding: 'NONE' | 'CEIL' | 'FLOOR';
  bundleId: number | null;
  productId: number | null;
  facilityId: number | null;
}

export interface IProductPrice {
  price: number | null;
  bundleId: number | null;
  bundlePriceFromProduct: boolean | null;
  bundleItemId: number | null;
  productId: number | null;
  facilityId: number | null;
  components: IProductPriceComponent[];
}
export interface IProductPriceComponent {
  taxTypeId: number;
  price: number;
}

export class PriceSelector<PriceSelectorType extends IPriceSelector, PriceRuleType extends IPriceRule> {
  protected priceLists: IPriceList<PriceSelectorType, PriceRuleType>[] | null = null;

  public hydrate(json: string) {
    const { priceLists } = JSON.parse(json);
    this.priceLists = priceLists;
  }
  public dehydrate(): string {
    return JSON.stringify({
      priceLists: this.priceLists
    });
  }

  public findPrice(input: PriceSelectorInput): PriceSelectorOutput<PriceSelectorType> | null {
    const __isRuleMatch = (price: PriceSelectorOutput<PriceSelectorType>, rule: PriceRuleType) => {
      if (!rule.bundleId && !rule.productId && !rule.facilityId) return true
      if (rule.bundleId && input.bundleId && rule.bundleId === input.bundleId) {
        return true
      }
      if (rule.productId && input.productId && rule.productId === input.productId) {
        return true
      }
      if (rule.facilityId && input.facilityId && rule.facilityId === input.facilityId) {
        return true
      }
    }
    const __applyPriceRules = (price: PriceSelectorOutput<PriceSelectorType>, rules: PriceRuleType[]): PriceSelectorOutput<PriceSelectorType> => {
      const result = {
        ...price,
        components: price.components.map(c => ({ ...c }))
      }
      if (result.price !== null) {
        for (const rule of rules) {
          if (__isRuleMatch(price, rule)) {
            if (rule.rule === 'FIXED') {
              const rate = (result.price + rule.rate) / result.price
              result.price = result.price + rule.rate
              for (const c of result.components) {
                c.price = c.price * rate
              }
            } else if (rule.rule === 'PERCENTAGE') {
              result.price = result.price * (1 + rule.rate)
              for (const c of result.components) {
                c.price = c.price * (1 + rule.rate)
              }
            } else if (rule.rule === 'OVERWRITE') {
              const rate = rule.rate / result.price
              result.price = rule.rate
              for (const c of result.components) {
                c.price = c.price * rate
              }
            }
            if (rule.rounding === 'CEIL') {
              const rate = Math.ceil(result.price) / result.price
              result.price = Math.ceil(result.price)
              for (const c of result.components) {
                c.price = c.price * rate
              }
            } else if (rule.rounding === 'FLOOR') {
              const rate = Math.floor(result.price) / result.price
              result.price = Math.floor(result.price)
              for (const c of result.components) {
                c.price = c.price * rate
              }
            }
          }
        }
      }
      return result
    }

    const __findPriceAndApplyRulesRec = (priceList: IPriceList<PriceSelectorType, PriceRuleType>): PriceSelectorOutput<PriceSelectorType> | null => {
      const price = this.__extractPriceFromPriceList(priceList, input)
      if (price) {
        return price;
      }
      if (priceList.parent) {
        const parentPrice = __findPriceAndApplyRulesRec(priceList.parent)
        if (parentPrice) {
          return __applyPriceRules(parentPrice, priceList.rules)
        }
      }
      return null
    }

    const pl = this.priceLists?.find(pl => pl.id === input.priceListId);
    if (!pl) return null

    return __findPriceAndApplyRulesRec(pl)
  }

  private __extractPriceFromPriceList(priceList: IPriceList<PriceSelectorType, PriceRuleType>, input: PriceSelectorInput): PriceSelectorOutput<PriceSelectorType> | null {
    const _findPrice = (prices: IProductPrice[]) =>
      prices.find(p => {
        if (input.bundleId && input.bundleId === p.bundleId) return true;
        if (input.bundleItemId && input.bundleItemId === p.bundleItemId) return true;
        if (input.productId && input.productId === p.productId) return true;
        if (input.facilityId && input.facilityId === p.facilityId) return true;
        return false;
      });

    if (input.selectorId) {
      const selector = priceList.selectors.find(s => s.id === input.selectorId);
      if (selector) {
        const price = _findPrice(selector.prices);
        if (price) {
          return {
            price: price.price,
            bundlePriceFromProduct: price.bundlePriceFromProduct,
            components: price.components,
            selector: selector,
          };
        }
      }
    } else {
      const possibleSelectors = priceList.selectors.filter(s => !s.isBasePrice && _findPrice(s.prices)) || [];

      if (possibleSelectors.length > 0) {
        const dateMom = moment(input.date);
        const weekday = toPricingWeekday(input.date);

        const _filterGuestCount = (s: IPriceSelector) =>
          (s.minGuestCount ? input.guestCount >= s.minGuestCount : true) && (s.maxGuestCount ? input.guestCount <= s.maxGuestCount : true);
        const _filterItemCount = (s: IPriceSelector) =>
          (s.minItemCount ? input.itemCount >= s.minItemCount : true) && (s.maxItemCount ? input.itemCount <= s.maxItemCount : true);
        const _filterDate = (s: IPriceSelector) =>
          (s.validFrom ? moment(s.validFrom).startOf('day').isSameOrBefore(dateMom) : true) && (s.validTo ? moment(s.validTo).endOf('day').isSameOrAfter(dateMom) : true);
        const _filterWeekday = (s: IPriceSelector) => (s.weekdays.length > 0 ? s.weekdays.find((w: any) => w.weekdays === weekday) : true);

        const matchingSelectors = possibleSelectors.filter(s => _filterGuestCount(s) && _filterItemCount(s) && _filterDate(s) && _filterWeekday(s));
        if (matchingSelectors.length === 1) {
          const price = _findPrice(matchingSelectors[0].prices);
          if (price) {
            return {
              price: price.price,
              bundlePriceFromProduct: price.bundlePriceFromProduct,
              components: price.components,
              selector: matchingSelectors[0],
            };
          }
        } else if (matchingSelectors.length > 1) {
          const [maxFilterCount, maxIndex] = matchingSelectors.reduce(
            (agg, selector, i) => {
              const filterCount =
                (selector.minGuestCount ? 1 : 0) +
                (selector.maxGuestCount ? 1 : 0) +
                (selector.minItemCount ? 1 : 0) +
                (selector.maxItemCount ? 1 : 0) +
                (selector.validFrom ? 1 : 0) +
                (selector.validTo ? 1 : 0) +
                (selector.weekdays.length > 0 ? 1 : 0);
              if (agg[0] < filterCount) return [filterCount, i];
              else return agg;
            },
            [Number.MIN_VALUE, -1],
          );

          const price = _findPrice(matchingSelectors[maxIndex].prices);
          if (price) {
            return {
              price: price.price,
              bundlePriceFromProduct: price.bundlePriceFromProduct,
              components: price.components,
              selector: matchingSelectors[maxIndex],
            };
          }
        }
      }
    }

    const baseSelector = priceList.selectors.find(s => s.isBasePrice);
    if (baseSelector) {
      const price = _findPrice(baseSelector.prices);
      if (price) {
        return {
          price: price.price,
          bundlePriceFromProduct: price.bundlePriceFromProduct,
          components: price.components,
          selector: baseSelector,
        };
      }
    }
    return null;
  }
}
