import { format, addDays } from 'date-fns/fp';
import ConfirmationAccordion from '../../ConfirmationAccordion';
import Divider from '../../Divider';
import TextField from '../../TextField';
import { CapsuleTabContentBody, CapsuleTabContentHeader, ContentRow } from '../CapsuleComponents';
import React, { useEffect } from 'react';
import SimpleTable from '../../SimpeTable';
import Button from '../../Button';
import Checkbox from '../../Checkbox';
import { IFormState } from '../Capsule';
import Icon from '../../Icon';
import FullScreenOverlay from '../../FullScreenOverlay';
import styled from 'styled-components';

import ExtrasSection, { hasFormStateAnyCheckedExtras, hasFormStateAnyVisibleExtras, hasFormStateCheckedExtrasForDay } from './ExtrasSection';
import { getDownloadUrl, getHTMLOfferPreview } from '../../../api/api';
import DrawerAccordion from '../../DrawerAccordion';
import WizardAccordion from '../../WizardAccordion';
import { OtelManager } from '../../../api/otel';
import { CountrySelectField } from '../../SelectField';

const BookingFormContainer = styled.div<{ mobile: boolean }>`
  display: ${props => (props.mobile ? 'block' : 'flex')};
  flex-direction: ${props => (props.mobile ? 'column' : 'row')};
  font-family: sans-serif;
  height: 100%;
  overflow-y: ${props => (props.mobile ? 'auto' : 'hidden')};
  max-width: 1300px;
  margin: 0 auto;
`;

const BookingFormGridItem = styled.div<{ mobile: boolean }>`
  overflow-y: auto;
  height: ${props => (props.mobile ? 'auto' : window.innerHeight - 40)};
  padding: 20px;
  width: ${props => (props.mobile ? '100%' : '50%')};
  box-sizing: border-box;

  /* ===== Scrollbar CSS ===== */
  /* Firefox */
  scrollbar-width: auto;
  scrollbar-color: lightgray #ffffff;

  /* Chrome, Edge, and Safari */
  &::-webkit-scrollbar {
    width: 16px;
  }

  &::-webkit-scrollbar-track {
    background: #ffffff;
  }

  &::-webkit-scrollbar-thumb {
    background-color: lightgray;
    border-radius: 10px;
    border: 3px solid #ffffff;
  }
`;

interface IBookingFormProps {
  setPopupShow: any;
  isMobileSize: any;
  setDrawerOpen: any;
  setFormState: any;
  formState: IFormState;
  errors: any;
  setErrors: any;
  setTermsAccepted: any;
  termsAccepted: any;
  reservationStatus: any;
  setReservationStatus: any;
  emailVerificationCode: any;
  setEmailVerificationCode: any;
  emailVerificationCodeInvalid: any;
  setEmailVerificationCodeInvalid: any;
  meetingRoomsDay: any;
  noRooms: any;
  hotelRef: any;
  color: any;
  totalPriceGross: any;
  totalPriceNet: any;
  privacyPolicyUrl: any;
  termsUrl: any;
  placeReservation: any;
  verifyReservationEmail: any;
  ValidateEmail: any;
  downloadUrl: any;
  hotelName: any;
  quickPriceCalculator: any;
}

const closeScreen = (setPopupShow: any, setReservationStatus: any) => {
  setPopupShow(null);
  setReservationStatus('READY');
};

const _renderCloseButton = (setPopupShow: any, setReservationStatus: any) => (
  <div
    style={{
      position: 'absolute',
      top: 0,
      right: 0,
      fontFamily: 'Roboto, sans-serif',
      cursor: 'pointer',
      padding: '20px 20px',
    }}
    tabIndex={0}
    aria-label="Schließen"
    onKeyDown={e => {
      if (e.key === 'Enter') {
        closeScreen(setPopupShow, setReservationStatus);
      }
    }}
    onClick={() => {
      closeScreen(setPopupShow, setReservationStatus);
    }}
  >
    X
  </div>
);

const _renderBookingForm = (
  reservationStatus: any,
  formState: IFormState,
  isMobileSize: any,
  errors: any,
  setFormState: any,
  emailVerificationCodeInvalid: any,
  emailVerificationCode: any,
  setEmailVerificationCode: any,
  setReservationStatus: any,
  setEmailVerificationCodeInvalid: any,
  setErrors: any,
  setTermsAccepted: any,
  termsAccepted: any,
  color: any,
  setDrawerOpen: any,
  setPopupShow: any,
  verifyReservationEmail: any,
  ValidateEmail: any,
  totalPriceGross: any,
  totalPriceNet: any,
  privacyPolicyUrl: any,
  termsUrl: any,
  hotelRef: any,
  noRooms: any,
  placeReservation: any,
  downloadUrl: any,
  hotelName: any,
) => (
  <>
    {reservationStatus === 'READY' && (
      <>
        <div style={{ margin: 10 }}>
          <strong>Ihre Firmenadresse:</strong>
        </div>
        <TextField
          htmlFor="company"
          aria-label="Firma *"
          label="Firma *"
          name="company"
          errors={errors}
          fullWidth={isMobileSize()}
          onChange={(e: any) => {
            setFormState({
              ...formState,
              company: e.target.value,
            });
          }}
          value={formState.company}
        />
        <TextField
          htmlFor="firstname"
          aria-label="Vorname *"
          label="Vorname *"
          name="firstname"
          errors={errors}
          fullWidth={isMobileSize()}
          onChange={(e: any) => {
            setFormState({
              ...formState,
              firstname: e.target.value,
            });
          }}
          value={formState.firstname}
        />
        <TextField
          htmlFor="lastname"
          aria-label="Nachname *"
          label="Nachname *"
          name="lastname"
          errors={errors}
          fullWidth={isMobileSize()}
          onChange={(e: any) => {
            setFormState({
              ...formState,
              lastname: e.target.value,
            });
          }}
          value={formState.lastname}
        />
        <TextField
          htmlFor="address"
          aria-label="Adresse *"
          label="Adresse *"
          name="address"
          errors={errors}
          fullWidth={isMobileSize()}
          onChange={(e: any) => {
            setFormState({
              ...formState,
              address: e.target.value,
            });
          }}
          value={formState.address}
        />
        <TextField
          htmlFor="zip"
          aria-label="PLZ *"
          label="PLZ *"
          name="zip"
          errors={errors}
          fullWidth={isMobileSize()}
          onChange={(e: any) => {
            setFormState({
              ...formState,
              zip: e.target.value,
            });
          }}
          value={formState.zip}
        />
        <TextField
          htmlFor="city"
          aria-label="Ort"
          label="Ort *"
          name="city"
          errors={errors}
          fullWidth={isMobileSize()}
          onChange={(e: any) => {
            setFormState({
              ...formState,
              city: e.target.value,
            });
          }}
          value={formState.city}
        />
        <CountrySelectField
          htmlFor="country"
          aria-label="Land *"
          label="Land *"
          name="country"
          errors={errors}
          fullWidth={isMobileSize()}
          onChange={(e: any) => {
            setFormState({
              ...formState,
              country: e.target.value,
            });
          }}
          value={formState.country}
        />
        <div
          style={{
            display: 'flex',
            flexDirection: isMobileSize() ? 'column' : 'row',
            paddingLeft: 5,
            paddingBottom: 10,
          }}
        >
          <Checkbox
            aria-label="Firmenadresse entspricht Rechnungsadresse"
            checked={formState.companyEqualsBilling}
            id="companyEqualsBilling"
            name="companyEqualsBilling"
            label="Firmenadresse entspricht Rechnungsadresse"
            onChange={(e: any) => {
              setFormState({
                ...formState,
                companyEqualsBilling: !formState.companyEqualsBilling,
              });
            }}
          />
        </div>
        {!formState.companyEqualsBilling && (
          <>
            <div style={{ margin: 10 }}>
              <strong>Ihre Rechnungsadresse:</strong>
            </div>
            <TextField
              htmlFor="billingCompany"
              aria-label="Firma *"
              label="Firma *"
              name="billingCompany"
              errors={errors}
              fullWidth={isMobileSize()}
              onChange={(e: any) => {
                setFormState({
                  ...formState,
                  billingCompany: e.target.value,
                });
              }}
              value={formState.billingCompany}
            />
            <TextField
              htmlFor="billingFirstname"
              aria-label="Vorname *"
              label="Vorname *"
              name="billingFirstname"
              errors={errors}
              fullWidth={isMobileSize()}
              onChange={(e: any) => {
                setFormState({
                  ...formState,
                  billingFirstname: e.target.value,
                });
              }}
              value={formState.billingFirstname}
            />
            <TextField
              htmlFor="billingLastname"
              aria-label="Nachname *"
              label="Nachname *"
              name="billingLastname"
              errors={errors}
              fullWidth={isMobileSize()}
              onChange={(e: any) => {
                setFormState({
                  ...formState,
                  billingLastname: e.target.value,
                });
              }}
              value={formState.billingLastname}
            />
            <TextField
              htmlFor="billingAddress"
              aria-label="Adresse *"
              label="Adresse *"
              name="billingAddress"
              errors={errors}
              fullWidth={isMobileSize()}
              onChange={(e: any) => {
                setFormState({
                  ...formState,
                  billingAddress: e.target.value,
                });
              }}
              value={formState.billingAddress}
            />
            <TextField
              htmlFor="billingZip"
              aria-label="PLZ *"
              label="PLZ *"
              name="billingZip"
              errors={errors}
              fullWidth={isMobileSize()}
              onChange={(e: any) => {
                setFormState({
                  ...formState,
                  billingZip: e.target.value,
                });
              }}
              value={formState.billingZip}
            />
            <TextField
              htmlFor="billingCity"
              aria-label="Ort *"
              label="Ort *"
              name="billingCity"
              errors={errors}
              fullWidth={isMobileSize()}
              onChange={(e: any) => {
                setFormState({
                  ...formState,
                  billingCity: e.target.value,
                });
              }}
              value={formState.billingCity}
            />
            <TextField
              htmlFor="billingAddress"
              aria-label="Adresse *"
              label="Adresse *"
              name="billingAddress"
              errors={errors}
              fullWidth={isMobileSize()}
              onChange={(e: any) => {
                setFormState({
                  ...formState,
                  billingAddress: e.target.value,
                });
              }}
              value={formState.billingAddress}
            />
            <TextField
              htmlFor="billingZip"
              aria-label="PLZ *"
              label="PLZ *"
              name="billingZip"
              errors={errors}
              fullWidth={isMobileSize()}
              onChange={(e: any) => {
                setFormState({
                  ...formState,
                  billingZip: e.target.value,
                });
              }}
              value={formState.billingZip}
            />
            <TextField
              htmlFor="billingCity"
              aria-label="Ort *"
              label="Ort *"
              name="billingCity"
              errors={errors}
              fullWidth={isMobileSize()}
              onChange={(e: any) => {
                setFormState({
                  ...formState,
                  billingCity: e.target.value,
                });
              }}
              value={formState.billingCity}
            />
            <CountrySelectField
              htmlFor="billingCountry"
              aria-label="Land *"
              label="Land *"
              name="billingCountry"
              errors={errors}
              fullWidth={isMobileSize()}
              onChange={(e: any) => {
                setFormState({
                  ...formState,
                  billingCountry: e.target.value,
                });
              }}
              value={formState.billingCountry}
            />
            <Divider />
          </>
        )}
        <TextField
          htmlFor="email"
          aria-label="E-Mail-Adresse *"
          label="E-Mail-Adresse *"
          name="email"
          fullWidth={isMobileSize()}
          errors={errors}
          onChange={(e: any) => {
            setFormState({
              ...formState,
              email: e.target.value,
            });
          }}
          value={formState.email}
        />
        <TextField
          htmlFor="phone"
          aria-label="Telefon *"
          label="Telefon *"
          name="phone"
          fullWidth={isMobileSize()}
          errors={errors}
          onChange={(e: any) => {
            setFormState({
              ...formState,
              phone: e.target.value,
            });
          }}
          value={formState.phone}
        />
        <TextField
          htmlFor="message"
          aria-label="Möchten Sie uns sonst noch etwas mitteilen?"
          label="Möchten Sie uns sonst noch etwas mitteilen?"
          name="comment"
          errors={errors}
          cols={2}
          rows={4}
          multiline={true}
          fullWidth={isMobileSize()}
          onChange={(e: any) => {
            setFormState({
              ...formState,
              comment: e.target.value,
            });
          }}
          value={formState.comment}
        />
      </>
    )}
  </>
);

const _formatSeatingString = (seating: any) => {
  switch (seating) {
    case 'UFORM':
      return 'U-Form';
    case 'THEATER':
      return 'Theater';
    case 'PARLAMENT':
      return 'Parlament';
    case 'CIRCLE':
      return 'Kreis';
    case 'BANKETT':
      return 'Bankett';
    case 'COCKTAIL':
      return 'Cocktail';
    case 'BLOCK':
      return 'Block';
    default:
      return seating;
  }
};

const _renderSummary = (formState: IFormState, meetingRoomsDay: any, noRooms: any, hotelName: any) => (
  <>
    <div style={{ padding: 10 }}>
      <h3>Zusammenfassung der Buchungsdetails</h3>
    </div>
    <div>
      <ConfirmationAccordion
        values={[
          {
            name: 'termin',
            label: 'Termin',
            description:
              (formState.start && formState.end && format('dd.MM.', formState.start) + ' - ' + format('dd.MM.yy', formState.end)) || 'keine',
            content: (
              <>
                {(formState.start && formState.end && format('dd.MM.yyyy', formState.start) + ' - ' + format('dd.MM.yyyy', formState.end)) || 'keine'}
              </>
            ),
          },
          {
            name: 'meetingRooms',
            label: 'Seminarräume',
            description: formState.meetingRooms.toString(),
            content: (
              <>
                <div
                  style={{
                    display: 'inline-block',
                    marginRight: 10,
                    fontFamily: 'Roboto, sans-serif',
                  }}
                >
                  <strong>Seminarräume:</strong>
                </div>
                {formState.meetingRooms}
                <div
                  style={{
                    display: 'inline-block',
                    marginRight: 10,
                    width: 120,
                    fontFamily: 'Roboto, sans-serif',
                  }}
                ></div>
                <Divider />
                <>
                  {formState.days.map((day, index) => (
                    <React.Fragment key={'mr_' + index}>
                      <h3 style={{ marginTop: 40 }}>Tag {index + 1}</h3>
                      <SimpleTable
                        name="meetingRoomsSummary"
                        verticalHeader={true}
                        header={['', 'Ganztägig', 'Vormittag', 'Nachmittag', 'Bestuhlung']}
                        data={day.occupancy.map((oc, index) => [
                          <strong>{'Seminarraum ' + (index + 1)}</strong>,
                          <>{oc === 'FULLDAY' && <>X</>}</>,
                          <>{oc === 'MORNING' && <>X</>}</>,
                          <>{oc === 'AFTERNOON' && <>X</>}</>,
                          <>{_formatSeatingString(day.seating[index])}</>,
                        ])}
                      />
                      <Divider />
                    </React.Fragment>
                  ))}
                </>
              </>
            ),
          },
          {
            name: 'guests',
            label: 'Gäste',
            description:
              Math.min(...formState.days.map(v => v.totalGuests)).toString() + ' / ' + Math.max(...formState.days.map(v => v.totalGuests)).toString(),
            content: (
              <>
                {formState.start &&
                  [...Array(formState.days.length)].map((_, index) => (
                    <React.Fragment key={index}>
                      <div
                        style={{
                          display: 'flex',
                          fontFamily: 'Roboto, sans-serif',
                          justifyContent: 'space-between',
                        }}
                      >
                        <div
                          style={{
                            minWidth: 50,
                            paddingRight: 40,
                            textAlign: 'left',
                          }}
                        >
                          <>
                            <strong>Tag {index + 1}</strong>
                            <div>{format('dd.MM.yyyy', addDays(index, formState.start || new Date()))}</div>
                          </>
                        </div>
                        <div
                          style={{
                            paddingRight: 0,
                            alignSelf: 'center',
                          }}
                        >
                          {formState.days[index].totalGuests}
                        </div>
                      </div>
                      <Divider />
                    </React.Fragment>
                  ))}
              </>
            ),
          },
          ...(noRooms
            ? []
            : [
                {
                  name: 'rooms',
                  label: 'Zimmer',
                  description: `${Math.max(...formState.days.map(v => v.overnightGuests), formState.prevdayGuests)}` || 'nicht gesetzt',

                  content: (
                    <>
                      {!formState.start && <div></div>}
                      <div
                        style={{
                          display: 'flex',
                          fontFamily: 'Roboto, sans-serif',
                          justifyContent: 'space-between',
                        }}
                      >
                        <div
                          style={{
                            minWidth: 50,
                            paddingRight: 40,
                            textAlign: 'left',
                          }}
                        >
                          <>
                            <strong>Voranreisetag</strong>
                            <div>{format('dd.MM.yyyy', addDays(-1, formState.start || new Date()))}</div>
                          </>
                        </div>
                        <div
                          style={{
                            paddingRight: 0,
                            alignSelf: 'center',
                          }}
                        >
                          {formState.prevdayGuests}
                        </div>
                      </div>
                      <Divider />
                      {formState.start &&
                        [...Array(formState.days.length)].map((_, index) => (
                          <React.Fragment key={index}>
                            <div
                              style={{
                                display: 'flex',
                                fontFamily: 'Roboto, sans-serif',
                                justifyContent: 'space-between',
                              }}
                            >
                              <div
                                style={{
                                  minWidth: 50,
                                  paddingRight: 40,
                                  textAlign: 'left',
                                }}
                              >
                                <>
                                  <strong>{formState.days.length - 1 === index ? 'Verlängerungsnacht' : `Tag ${index + 1}`}</strong>
                                  <div>{format('dd.MM.yyyy', addDays(index, formState.start || new Date()))}</div>
                                </>
                              </div>
                              <div
                                style={{
                                  paddingRight: 0,
                                  alignSelf: 'center',
                                }}
                              >
                                {formState.days[index].overnightGuests}
                              </div>
                            </div>
                            <Divider />
                          </React.Fragment>
                        ))}
                    </>
                  ),
                },
              ]),
          {
            name: 'service',
            label: 'Service',
            icons: ['flipchart', 'coffee'],
            description: formState.service?.headerShort || formState.service?.header,
            content: (
              <>
                <h4 style={{ marginTop: 0 }}>{formState.service.header}</h4>
                <div style={{ whiteSpace: 'pre-line' }}>{formState.service.details}</div>
              </>
            ),
          },
          ...(hasFormStateAnyCheckedExtras(formState)
            ? [
                {
                  name: 'extras',
                  label: 'Extras',
                  icons: ['flipchart', 'coffee'],
                  content: (
                    <>
                      {formState.days.map(
                        (day, extrasDay) =>
                          hasFormStateCheckedExtrasForDay(formState, extrasDay) && (
                            <React.Fragment key={extrasDay}>
                              <h3>Tag {extrasDay + 1}</h3>
                              {formState.days[extrasDay].addonFacilities.filter(f => f.visible && f.checked).length > 0 && (
                                <>
                                  <strong>Ausstattung</strong>
                                  <table>
                                    <tbody>
                                      {formState.days[extrasDay].addonFacilities
                                        .filter(f => f.visible && f.checked)
                                        .map((facility, index: number) => (
                                          <tr key={index}>
                                            <td
                                              style={{
                                                padding: 5,
                                                width: 150,
                                              }}
                                            >
                                              {(facility.recurring === 'BYDAY' || facility.recurring === 'BYCOUNT') && <>{facility.count} x </>}
                                              {facility.header}
                                            </td>
                                            <td style={{ padding: 5 }}>{facility.details}</td>
                                          </tr>
                                        ))}
                                    </tbody>
                                  </table>
                                </>
                              )}
                              <br />
                              {formState.days[extrasDay].addonProducts.filter(p => p.visible && p.checked).length > 0 && (
                                <>
                                  <strong>Zusatzleistungen</strong>
                                  <table>
                                    <tbody>
                                      {formState.days[extrasDay].addonProducts
                                        .filter(p => p.visible && p.checked)
                                        .map((product, index: number) => (
                                          <tr key={index}>
                                            <td
                                              style={{
                                                padding: 5,
                                                width: 150,
                                              }}
                                            >
                                              {product.recurring === 'BYCOUNT' && <>{product.count} x </>}
                                              {product.header}
                                            </td>
                                            <td style={{ padding: 5 }}>
                                              <div>{product.details}</div>
                                            </td>
                                          </tr>
                                        ))}
                                    </tbody>
                                  </table>
                                </>
                              )}
                              {formState.days[extrasDay].addonProducts.filter(p => p.visible && p.checked).length === 0 &&
                                formState.days[extrasDay].addonFacilities.filter(f => f.visible && f.checked).length === 0 &&
                                'keine Extras'}
                              <Divider />
                            </React.Fragment>
                          ),
                      )}
                    </>
                  ),
                },
              ]
            : []),
        ]}
      />
    </div>
  </>
);

const BookingForm = ({
  setPopupShow,
  isMobileSize,
  setDrawerOpen,
  setFormState,
  formState,
  errors,
  setErrors,
  setTermsAccepted,
  termsAccepted,
  reservationStatus,
  setReservationStatus,
  emailVerificationCode,
  setEmailVerificationCode,
  emailVerificationCodeInvalid,
  setEmailVerificationCodeInvalid,
  meetingRoomsDay,
  noRooms,
  hotelRef,
  color,
  totalPriceGross,
  totalPriceNet,
  privacyPolicyUrl,
  termsUrl,
  placeReservation,
  verifyReservationEmail,
  ValidateEmail,
  downloadUrl,
  hotelName,
  quickPriceCalculator,
}: IBookingFormProps) => {
  useEffect(() => {
    if (reservationStatus === 'DONE') {
      OtelManager.getInstance().widgetSetCurrentView('form-done');
    }
    if (reservationStatus === 'EMAILVERIFICATION') {
      OtelManager.getInstance().widgetSetCurrentView('form-email-verification');
    }
  }, [reservationStatus]);

  const validateForm = () => {
    let err: any = {};
    if (ValidateEmail(formState.email) === false) {
      err.email = 'Bitte geben Sie eine gültige E-Mail Adresse ein.';
    }
    if (formState.email === '') {
      err.email = 'Bitte geben Sie eine E-Mail Adresse ein.';
    }
    if (formState.phone === '') {
      err.phone = 'Bitte geben Sie eine Telefonnummer ein.';
    }
    if (formState.company === '') {
      err.company = 'Bitte geben Sie eine Firma ein.';
    }
    if (formState.company.length < 3) {
      err.company = 'Bitte geben Sie eine gültige Firma mit mindestens 3 Buchstaben ein.';
    }
    if (formState.firstname === '') {
      err.firstname = 'Bitte geben Sie einen Vornamen ein.';
    }
    if (formState.firstname.length < 3) {
      err.firstname = 'Bitte geben Sie einen gülten Vornamen mit mindestens 3 Buchstaben ein.';
    }
    if (formState.lastname === '') {
      err.lastname = 'Bitte geben Sie einen Nachnamen ein.';
    }
    if (formState.lastname.length < 3) {
      err.lastname = 'Bitte geben Sie einen gülten Nachnamen mit mindestens 3 Buchstaben ein.';
    }
    if (formState.zip === '') {
      err.zip = 'Bitte geben Sie eine Postleitzahl an.';
    }
    if (formState.zip.length < 3) {
      err.zip = 'Bitte geben Sie eine gültige Postleitzahl an.';
    }
    if (formState.address === '') {
      err.address = 'Bitte geben Sie eine Adresse an.';
    }
    if (formState.address.length < 3) {
      err.address = 'Bitte geben Sie eine gültige Adresse an.';
    }
    if (formState.city === '') {
      err.city = 'Bitte geben Sie ein Bundesland / eine Region an.';
    }
    if (formState.city.length < 3) {
      err.city = 'Bitte geben Sie ein Bundesland / eine Region an.';
    }
    if (!formState.companyEqualsBilling) {
      if (formState.billingCompany === '') {
        err.billingCompany = 'Bitte geben Sie eine Firma ein.';
      }
      if (formState.billingCompany.length < 3) {
        err.billingCompany = 'Bitte geben Sie eine gültige Firma mit mindestens 3 Buchstaben ein.';
      }
      if (formState.billingFirstname === '') {
        err.billingFirstname = 'Bitte geben Sie einen Vornamen ein.';
      }
      if (formState.billingFirstname.length < 3) {
        err.billingFirstname = 'Bitte geben Sie einen gülten Vornamen mit mindestens 3 Buchstaben ein.';
      }
      if (formState.billingLastname === '') {
        err.billingLastname = 'Bitte geben Sie einen Nachnamen ein.';
      }
      if (formState.billingLastname.length < 3) {
        err.billingLastname = 'Bitte geben Sie einen gülten Nachnamen mit mindestens 3 Buchstaben ein.';
      }
      if (formState.billingZip === '') {
        err.billingZip = 'Bitte geben Sie eine Postleitzahl an.';
      }
      if (formState.billingZip.length < 3) {
        err.billingZip = 'Bitte geben Sie eine gültige Postleitzahl an.';
      }
      if (formState.billingAddress === '') {
        err.billingAddress = 'Bitte geben Sie eine Adresse an.';
      }
      if (formState.billingAddress.length < 3) {
        err.billingAddress = 'Bitte geben Sie eine gültige Adresse an.';
      }
      if (formState.billingCity === '') {
        err.billingCity = 'Bitte geben Sie ein Bundesland / eine Region an.';
      }
      if (formState.billingCity.length < 3) {
        err.billingCity = 'Bitte geben Sie ein Bundesland / eine Region an.';
      }
    }
    setErrors(err);
    if (Object.keys(err).length > 0) {
      return false;
    }
    return true;
  };

  const [offerHtml, setOfferHtml] = React.useState('');
  const [htmlTimeout, setHtmlTimeout] = React.useState<any>(null);

  React.useEffect(() => {
    clearTimeout(htmlTimeout);
    setHtmlTimeout(
      setTimeout(() => {
        getHTMLOfferPreview({
          input: {
            hotelRef: hotelRef!,
            language: 'de',
            startDate: formState.start,
            endDate: formState.end,
            days: formState.days.map(day => ({
              ...day,
              addonProducts: day.addonProducts
                .filter(p => p.visible && p.checked)
                .map(product => ({
                  lookupCode: product.lookupCode,
                  sku: product.sku,
                  count: product.count,
                })),
              addonFacilities: day.addonFacilities
                .filter(f => f.visible && f.checked)
                .map(facility => ({
                  lookupCode: facility.lookupCode,
                  sku: facility.sku,
                  count: facility.count,
                })),
            })),
            prevdayGuests: formState.prevdayGuests,
            serviceTypeSku: formState.service.sku,
            comment: formState.comment,
          },
          client: {
            company: formState.company,
            email: formState.email,
            firstname: formState.firstname,
            lastname: formState.lastname,
            address: formState.address,
            zip: formState.zip,
            city: formState.city,
            country: formState.country,
            phone: formState.phone,
            billingCompany: formState.companyEqualsBilling ? formState.company : formState.billingCompany,
            billingFirstname: formState.companyEqualsBilling ? formState.firstname : formState.billingFirstname,
            billingLastname: formState.companyEqualsBilling ? formState.lastname : formState.billingLastname,
            billingAddress: formState.companyEqualsBilling ? formState.address : formState.billingAddress,
            billingZip: formState.companyEqualsBilling ? formState.zip : formState.billingZip,
            billingCity: formState.companyEqualsBilling ? formState.city : formState.billingCity,
            billingCountry: formState.companyEqualsBilling ? formState.country : formState.billingCountry,
          },
        }).then(res => {
          setOfferHtml(res.html);
        });
      }, 1000),
    );
  }, [formState]);

  const resStatus = React.useRef<HTMLDivElement>(null);

  return (
    <FullScreenOverlay
      setPopupShow={setPopupShow}
      closeFunc={() => {
        closeScreen(setPopupShow, setReservationStatus);
      }}
    >
      {_renderCloseButton(setPopupShow, setReservationStatus)}
      <BookingFormContainer mobile={isMobileSize()}>
        <BookingFormGridItem mobile={isMobileSize()}>
          {hotelName && (
            <div style={{ padding: 10 }}>
              <strong>Vielen Dank für Ihr Interesse an {hotelName}!</strong>
            </div>
          )}
          <div style={{ padding: 10 }}>
            Wir prüfen Ihre Wunschkonfiguration auf Verfügbarkeit und melden uns umgehend bei Ihnen. Bitte beachten Sie, dass dies noch keine
            verbindliche Reservierung ist.
          </div>
          <div style={{ position: 'relative', height: 'calc(100% - 200px)' }}>
            <div style={{ position: 'absolute' }}>
              {reservationStatus !== 'DONE' && (
                <img
                  src={`${process.env.REACT_APP_WIDGET_URL || ''}/assets/images/nicht-uebermittelt.png`}
                  style={{ width: '150px', height: '150px' }}
                />
              )}
              {reservationStatus === 'DONE' && (
                <img src={`${process.env.REACT_APP_WIDGET_URL || ''}/assets/images/uebermittelt.png`} style={{ width: '150px', height: '150px' }} />
              )}
            </div>
            <iframe srcDoc={offerHtml} width="100%" style={{ height: '100%', border: '1px solid #ccc' }} />
          </div>
          <div
            style={{
              display: 'flex',
              justifyContent: 'flex-end',
              alignItems: 'center',
              paddingTop: 10,
            }}
          >
            Hier können Sie Ihr Angebot downloaden
            <button
              aria-label="Angebot downloaden"
              style={{
                display: 'flex',
                alignSelf: 'center',
                flexDirection: 'column',
                padding: 10,
                cursor: 'pointer',
                backgroundColor: 'transparent',
                border: 'none',
              }}
              onClick={() => {
                OtelManager.getInstance().downloadPdfClickCounterInc(formState);
                downloadUrl && window.open(downloadUrl, '_blank');
              }}
            >
              <Icon name="download" color={color} />
            </button>
            <div
              style={{
                margin: 10,
                display: 'flex',
                justifyContent: 'flex-end',
              }}
            >
              {totalPriceGross && totalPriceGross && (
                <div>
                  <div style={{ fontSize: 18, fontWeight: 'bold' }}>
                    {(totalPriceNet || 0)
                      .toString()
                      .replace('.', ',')
                      .replace(/\B(?=(\d{3})+(?!\d))/g, '.')}
                    €
                  </div>
                  <div style={{ fontSize: 10 }}>+ MwSt.</div>
                </div>
              )}
            </div>
          </div>
        </BookingFormGridItem>
        <BookingFormGridItem mobile={isMobileSize()}>
          <div ref={resStatus}>
            {reservationStatus === 'DONE' && (
              <>
                <div style={{ margin: 10 }}>
                  <strong>Danke für Ihre Anfrage!</strong>
                  <div>Sie erhalten in Kürze eine Anfragebestätigung per E-Mail.</div>
                </div>
              </>
            )}
            {reservationStatus === 'ERROR' && (
              <>
                <div style={{ margin: 10 }}>
                  <strong>Ein Fehler ist aufgetreten!</strong>
                </div>
              </>
            )}
            {reservationStatus === 'LOADING' && (
              <>
                <div style={{ margin: 10 }}>
                  <strong>Anfrage wird gesendet...</strong>
                </div>
              </>
            )}
            {reservationStatus === 'EMAILVERIFICATION' && (
              <div style={{ margin: 10 }}>
                <div>
                  Um die Anfrage abzuschließen, bestätigen Sie bitte Ihre E-Mail-Adresse durch Eingabe des Codes, welchen Sie in Kürze per E-Mail
                  erhalten werden.
                </div>
                {emailVerificationCodeInvalid && <div>Der eingegebene Code war ungültig. Bitte versuchen Sie es nochmal.</div>}
                <div
                  style={{
                    display: 'flex',
                    flexDirection: isMobileSize() ? 'column' : 'row',
                    paddingTop: 10,
                  }}
                >
                  <TextField
                    htmlFor="emailVerificationCode"
                    grey={true}
                    label="Verifizierungscode"
                    name="eamilVerificationCode"
                    errors={errors}
                    fullWidth={isMobileSize()}
                    onChange={(e: any) => {
                      setEmailVerificationCode(e.target.value);
                    }}
                    value={emailVerificationCode}
                  />
                </div>
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'flex-start',
                    paddingTop: 10,
                  }}
                >
                  <Button
                    color={color}
                    filled={true}
                    fullWidth={isMobileSize()}
                    disabled={!emailVerificationCode}
                    onClick={async () => {
                      try {
                        setReservationStatus('LOADING');
                        const res = await verifyReservationEmail(hotelRef!, emailVerificationCode);
                        if (res) {
                          OtelManager.getInstance().emailVerificationCounterInc('successful', formState);
                          setReservationStatus('DONE');
                          setEmailVerificationCodeInvalid(false);
                        } else {
                          OtelManager.getInstance().emailVerificationCounterInc('failed', formState);
                          setReservationStatus('EMAILVERIFICATION');
                          setEmailVerificationCodeInvalid(true);
                        }
                      } catch (e) {
                        setReservationStatus('ERROR');
                        setEmailVerificationCodeInvalid(false);
                      }
                    }}
                  >
                    Buchungsanfrage absenden
                  </Button>
                </div>
              </div>
            )}
            {reservationStatus === 'READY' && (
              <WizardAccordion
                closeFunc={() => closeScreen(setPopupShow, setReservationStatus)}
                errors={errors}
                onChange={value => {
                  if (value.name === 'summary') {
                    validateForm();
                  }
                }}
                endSection={
                  <div>
                    {privacyPolicyUrl && (
                      <div style={{ padding: 10 }}>
                        Wir verarbeiten Ihre Daten ausschließlich zur Bearbeitung Ihres Anliegens. Weitere Informationen zum Umgang mit
                        personenbezogenen Daten finden Sie{' '}
                        <a href={privacyPolicyUrl} target="_blank">
                          hier
                        </a>
                        .
                      </div>
                    )}
                    <div style={{ padding: 10 }}>
                      <Checkbox
                        type="checkbox"
                        name="termsAccepted"
                        checked={termsAccepted}
                        onChange={(event: any) => {
                          setTermsAccepted(!!event.target.checked);
                        }}
                        label={
                          <>
                            Mit dem Absenden der Buchungsanfrage akzeptiere ich die{' '}
                            {termsUrl ? (
                              <a aria-label="Zu den AGB" href={termsUrl} target="_blank">
                                AGB
                              </a>
                            ) : (
                              'AGB'
                            )}{' '}
                            und die{' '}
                            {privacyPolicyUrl ? (
                              <a aria-label="Zur Datenschutzerklärung" href={privacyPolicyUrl} target="_blank">
                                Datenschutzerklärung
                              </a>
                            ) : (
                              'Datenschutzerklärung'
                            )}
                            .
                          </>
                        }
                      />
                    </div>
                  </div>
                }
                color={color}
                isMobileSize={isMobileSize}
                values={[
                  ...(hasFormStateAnyVisibleExtras(formState)
                    ? [
                        {
                          name: 'extras',
                          label: '1. Zusatzleistungen',
                          content: <ExtrasSection quickPriceCalculator={quickPriceCalculator} formState={formState} setFormState={setFormState} />,
                        },
                      ]
                    : []),
                  {
                    name: 'contact',
                    label: `${hasFormStateAnyVisibleExtras(formState) ? '2' : '1'}. Eingabe Ihrer Kontaktdaten`,
                    content: _renderBookingForm(
                      reservationStatus,
                      formState,
                      isMobileSize,
                      errors,
                      setFormState,
                      emailVerificationCodeInvalid,
                      emailVerificationCode,
                      setEmailVerificationCode,
                      setReservationStatus,
                      setEmailVerificationCodeInvalid,
                      setErrors,
                      setTermsAccepted,
                      termsAccepted,
                      color,
                      setDrawerOpen,
                      setPopupShow,
                      verifyReservationEmail,
                      ValidateEmail,
                      totalPriceGross,
                      totalPriceNet,
                      privacyPolicyUrl,
                      termsUrl,
                      hotelRef,
                      noRooms,
                      placeReservation,
                      downloadUrl,
                      hotelName,
                    ),
                    onNextClicked: next => {
                      if (validateForm()) {
                        next();
                      }
                    },
                  },
                  {
                    name: 'summary',
                    label: `${hasFormStateAnyVisibleExtras(formState) ? '3' : '2'}. Zusammenfassung`,
                    content: _renderSummary(formState, meetingRoomsDay, noRooms, hotelName),
                    onLoad: () => {
                      validateForm();
                    },
                  },
                ]}
                startButton={
                  <Button color={color} filled={false} fullWidth={isMobileSize()} onClick={() => closeScreen(setPopupShow, setReservationStatus)}>
                    <Icon name="chevronleft" color={color} /> Seminardetails bearbeiten
                  </Button>
                }
                endButton={
                  <Button
                    color={color}
                    filled={true}
                    fullWidth={isMobileSize()}
                    disabled={!termsAccepted || Object.keys(errors).length > 0}
                    onKeyDown={e => {
                      if (e.key === 'Tab') {
                        closeScreen(setPopupShow, setReservationStatus);
                      }
                    }}
                    onClick={async () => {
                      try {
                        setReservationStatus('LOADING');
                        const res = await placeReservation({
                          input: {
                            hotelRef: hotelRef!,
                            language: 'de',
                            startDate: format('yyyy-MM-dd', formState.start),
                            endDate: format('yyyy-MM-dd', formState.end),
                            days: formState.days.map(day => ({
                              ...day,
                              addonProducts: day.addonProducts
                                .filter(p => p.visible && p.checked)
                                .map(product => ({
                                  lookupCode: product.lookupCode,
                                  sku: product.sku,
                                  count: product.count,
                                })),
                              addonFacilities: day.addonFacilities
                                .filter(f => f.visible && f.checked)
                                .map(facility => ({
                                  lookupCode: facility.lookupCode,
                                  sku: facility.sku,
                                  count: facility.count,
                                })),
                            })),
                            prevdayGuests: formState.prevdayGuests,
                            serviceTypeSku: formState.service.sku,
                            comment: formState.comment,
                          },
                          client: {
                            company: formState.company,
                            email: formState.email,
                            firstname: formState.firstname,
                            lastname: formState.lastname,
                            address: formState.address,
                            zip: formState.zip,
                            city: formState.city,
                            country: formState.country,
                            phone: formState.phone,
                            billingCompany: formState.companyEqualsBilling ? formState.company : formState.billingCompany,
                            billingFirstname: formState.companyEqualsBilling ? formState.firstname : formState.billingFirstname,
                            billingLastname: formState.companyEqualsBilling ? formState.lastname : formState.billingLastname,
                            billingAddress: formState.companyEqualsBilling ? formState.address : formState.billingAddress,
                            billingZip: formState.companyEqualsBilling ? formState.zip : formState.billingZip,
                            billingCity: formState.companyEqualsBilling ? formState.city : formState.billingCity,
                            billingCountry: formState.companyEqualsBilling ? formState.country : formState.billingCountry,
                          },
                        });
                        if (res.emailVerificationRequired) {
                          setReservationStatus('EMAILVERIFICATION');
                        } else {
                          setReservationStatus('DONE');
                        }
                      } catch (e) {
                        setReservationStatus('ERROR');
                      }
                    }}
                  >
                    Buchungsanfrage absenden
                  </Button>
                }
              />
            )}
          </div>
        </BookingFormGridItem>
      </BookingFormContainer>
    </FullScreenOverlay>
  );
};

export default BookingForm;
