import { useEffect, useRef, useState } from 'react';
import styled, { StyleSheetManager, css } from 'styled-components';
import { createShadow, shadowRootFocusListener } from '../../helper';
import { createPortal } from 'react-dom';

const CapsuleTabContentWrapper = styled.div<{
  leftPosition: number | undefined;
  topPosition: number | undefined;
  expanded: boolean;
  width: string | number | undefined;
  covered: boolean;
  fullScreen: boolean | undefined;
}>`
  background-color: #ffffff;
  height: ${props => (props.fullScreen ? '100%' : 'auto')};
  border-bottom-left-radius: ${props => (props.fullScreen ? '0px' : '32px')};
  border-bottom-right-radius: ${props => (props.fullScreen ? '0px' : '32px')};
  position: ${props => (props.fullScreen ? 'fixed' : 'absolute')};
  box-sizing: border-box;
  top: ${props => props.topPosition && props.topPosition - 4}px;
  left: ${props => props.leftPosition}px;
  z-index: ${props => (props.covered ? 9999 : 8000)};
  visibility: ${props => (props.expanded ? 'visible' : 'hidden')};
  padding-left: 0px;
  padding-right: 0px;
  padding-bottom: 0px;
  text-align: left;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  width: ${props => props.width};
  box-: 0px 5px 5px 1px rgb(239, 244, 250);
  overflow: hidden;
  overflow-y: ${props => (props.fullScreen ? 'scroll' : 'hidden')};
  &::-webkit-scrollbar {
    width: 0px;
    background: transparent;
  }
  &:focus {
    outline: none;
  }
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
`;

interface ICapsulePopupProps {
  leftPosition: number | undefined;
  topPosition: number | undefined;
  expanded: boolean;
  width: string | number | undefined;
  covered: boolean;
  fullScreen: boolean | undefined;
  children?: React.ReactNode;
  setPopupShow: (show: string | null) => void;
  tabRef: any;
}

const CapsulePopup = ({ leftPosition, topPosition, expanded, width, covered, fullScreen, children, setPopupShow, tabRef }: ICapsulePopupProps) => {
  const [shadow, setShadow] = useState<ShadowRoot | null>(null);

  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const shadow = createShadow(document.body);
    setShadow(shadow);
    let interval: any = null;
    let timeout: any = null;
    timeout = setTimeout(() => {
      ref.current?.focus();
      ref.current?.addEventListener('focus', shadowRootFocusListener, true);
      ref.current?.addEventListener('keydown', (e) => {
       const target = e.target as any;
        const l = ref.current?.querySelectorAll('input, button')
        const last = l && l[l.length - 1]
        if (target === last) {
          e.preventDefault();
          tabRef.current?.focus();
          setPopupShow(null);
        }
      }, true)
    }, 200);
  }, []);

  return (
    <>
      {shadow &&
        createPortal(
          <StyleSheetManager target={shadow}>
            <div style={{ all: 'initial' }}>
              <CapsuleTabContentWrapper
                ref={ref}
                tabIndex={-1}
                leftPosition={leftPosition}
                topPosition={topPosition}
                expanded={expanded}
                width={width}
                covered={covered}
                fullScreen={fullScreen}
                onKeyUp={e => {
                  if (e.key === 'Escape') {
                    tabRef.current?.focus();
                    setPopupShow(null);
                  }
                }}
              >
                {children}
              </CapsuleTabContentWrapper>
            </div>
          </StyleSheetManager>,
          shadow,
        )}
    </>
  );
};

export default CapsulePopup;
