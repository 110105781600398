import _ from 'lodash';
import { ICountry, TCountryCode, countries, getEmojiFlag } from "countries-list";

const regionNamesInGerman = new Intl.DisplayNames(['de'], { type: 'region' });

export const allCountries = Object.keys(countries).map(code => {
  const country: ICountry = (countries as any)[code];

  return {
    code,
    name: regionNamesInGerman.of(code),
    native: country.native,
    label: `${getEmojiFlag(code as TCountryCode)} ${regionNamesInGerman.of(code)}`,
    emoji: getEmojiFlag(code as TCountryCode),
  };
});

export const allCountriesSorted = [
  allCountries.find(c => c.code === 'AT')!,
  allCountries.find(c => c.code === 'DE')!,
  allCountries.find(c => c.code === 'CH')!,
  ..._.sortBy(
    allCountries.filter(c => c.code !== 'AT' && c.code !== 'DE' && c.code !== 'CH'),
    c => c.name,
  ),
];