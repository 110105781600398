import DaySwitch from '../../DaySwitch';
import Divider from '../../Divider';
import NumField from '../../NumField';
import Radio from '../../Radio';
import SimpleTable from '../../SimpeTable';
import { IFormState } from '../Capsule';
import { addDays } from 'date-fns/fp';
import { CapsuleTabContentBody, CapsuleTabContentHeader, ContentRow } from '../CapsuleComponents';
import Dropdown from '../../DropDown';
import AddRemoveButton from '../../AddRemoveButton';
import { AvailabilityType } from '../../../semshared/availability/availability';

interface MeetingRoomsSectionProps {
  formState: IFormState;
  setFormState: any;
  meetingRoomsDay: any;
  setMeetingRoomsDay: any;
  shadowRoot: any;
  availabilityChecker: AvailabilityType | null;
}

const MeetingRoomsSectionDrawer = ({
  formState,
  setFormState,
  meetingRoomsDay,
  setMeetingRoomsDay,
  shadowRoot,
  availabilityChecker,
}: MeetingRoomsSectionProps) => (
  <>
    <CapsuleTabContentHeader>
      <ContentRow>
        <DaySwitch
          value={meetingRoomsDay}
          max={formState.days.length - 1}
          onChange={v => setMeetingRoomsDay(v)}
          date={addDays(meetingRoomsDay, formState.start)}
        />
      </ContentRow>
    </CapsuleTabContentHeader>
    <SimpleTable
      name="meetingRooms"
      verticalHeader={false}
      centeredHeader={true}
      header={['', 'Dauer', 'Bestuhlung', '']}
      data={formState.days[meetingRoomsDay].occupancy.map((m, index) => [
        <strong style={{ whiteSpace: 'nowrap' }}>{'Raum ' + (index + 1)}</strong>,
        <Dropdown
          ariaLabel={`Dauer - Raum ${index + 1}`}
          popupPosition="top"
          shadowRoot={shadowRoot}
          value={formState.days[meetingRoomsDay].occupancy[index]}
          onChange={value =>
            setFormState({
              ...formState,
              days: formState.days.map((d, i) => {
                if (i === meetingRoomsDay) {
                  return {
                    ...d,
                    occupancy: d.occupancy.map((s, j) => {
                      if (j === index) {
                        return value;
                      }
                      return s;
                    }),
                  };
                }
                return d;
              }),
            })
          }
          options={[
            { value: 'FULLDAY', label: 'Ganztägig' },
            { value: 'MORNING', label: 'Vormittag' },
            { value: 'AFTERNOON', label: 'Nachmittag' },
          ]}
        />,
        <Dropdown
          ariaLabel={`Bestuhlung - Raum ${index + 1}`}
          popupPosition="top"
          shadowRoot={shadowRoot}
          value={formState.days[meetingRoomsDay].seating[index]}
          onChange={value =>
            setFormState({
              ...formState,
              days: formState.days.map((d, i) => {
                if (i === meetingRoomsDay) {
                  return {
                    ...d,
                    seating: d.seating.map((s, j) => {
                      if (j === index) {
                        return value;
                      }
                      return s;
                    }),
                  };
                }
                return d;
              }),
            })
          }
          options={[
            {
              value: 'UFORM',
              label: 'U-Form',
              icon: <img src={`${process.env.REACT_APP_WIDGET_URL || ''}/assets/icons/U-form.png`} aria-hidden />,
            },
            {
              value: 'THEATER',
              label: 'Theater',
              icon: <img src={`${process.env.REACT_APP_WIDGET_URL || ''}/assets/icons/theater.png`} aria-hidden />,
            },
            {
              value: 'PARLAMENT',
              label: 'Parlament',
              icon: <img src={`${process.env.REACT_APP_WIDGET_URL || ''}/assets/icons/parlament.png`} aria-hidden />,
            },
            {
              value: 'CIRCLE',
              label: 'Kreis',
              icon: <img src={`${process.env.REACT_APP_WIDGET_URL || ''}/assets/icons/kreis.png`} aria-hidden />,
            },
            {
              value: 'BANKETT',
              label: 'Bankett',
              icon: <img src={`${process.env.REACT_APP_WIDGET_URL || ''}/assets/icons/bankett.png`} aria-hidden />,
            },
            {
              value: 'COCKTAIL',
              label: 'Cocktail',
              icon: <img src={`${process.env.REACT_APP_WIDGET_URL || ''}/assets/icons/cocktail.png`} aria-hidden />,
            },
            {
              value: 'BLOCK',
              label: 'Block',
              icon: <img src={`${process.env.REACT_APP_WIDGET_URL || ''}/assets/icons/block.png`} aria-hidden />,
            },
          ]}
        />,
        <AddRemoveButton
          aria-label={`Raum ${index + 1} löschen`}
          iconOnly={true}
          onClick={() => {
            const newDays = formState.days[meetingRoomsDay];
            newDays.occupancy.splice(index, 1);
            newDays.seating.splice(index, 1);
            setFormState({
              ...formState,
              days: formState.days.map((d, i) => {
                if (i === meetingRoomsDay) {
                  return newDays;
                }
                return d;
              }),
            });
          }}
        >
          -
        </AddRemoveButton>,
      ])}
    />
    <br />
    <AddRemoveButton
      aria-label="Seminarraum hinzufügen"
      iconOnly={false}
      onClick={() => {
        const newDays = formState.days[meetingRoomsDay];
        if (availabilityChecker && newDays.occupancy.length >= availabilityChecker?.getMaxMeetingRooms(addDays(meetingRoomsDay, formState.start))) {
          return;
        }
        newDays.occupancy.push('FULLDAY');
        newDays.seating.push('UFORM');
        setFormState({
          ...formState,
          days: formState.days.map((d, i) => {
            if (i === meetingRoomsDay) {
              return newDays;
            }
            return d;
          }),
        });
      }}
    >
      + Seminarraum hinzufügen
    </AddRemoveButton>
  </>
);

export default MeetingRoomsSectionDrawer;
